
// use for user authentication purposes ...
import {createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Axios from 'axios';


export const getAllPositions =  createAsyncThunk("positions/getAll",  async () => {
      
      let response = [];

      try {
            response = await Axios.get(`/api-uprising/positions`)
                                  .then ((res) => res.data);
            
      } catch (error) {
            // console.error(error);
            alert (`error ${error}`)
      }

     // alert (`nice thunkc ${Object.keys(response)}`)
    //  alert (`JSON.stringify(getAllPositions) ${JSON.stringify(response)}`)

      return response;

 });


 const initialState = {
      List:       [],
      Profile:    {
                     PosID:         '',
                     Position:      '',
                  },
      fetchStatus:     'Idle'   // 'Idle' || 'Loading' || 'Succeeded' || 'Failed' 
};

// reducers:  {},
      

const positionsSlice = createSlice (
   {
      name: 'positions',
      initialState,
      reducers: {
            setPosition:    (state, action) => {
                                             
                  // state = {...state, Username: action.payload.Username, Password: action.payload.Password,  isValidate: getValidate(action.payload)}; 
                  // state = {...state, isValidate: action.payload.isValidate}
                  // state = {...state, ...action.payload};
                  state = {...state, ...action.payload};
                 
                  return state;
         },
      },

      extraReducers: (builder) => {
               builder
                 .addCase(getAllPositions.pending, (state, action) => {
                       state = {...state, fetchStatus: 'Idle',  List: [] };    
                       return state;       
                 })
                 .addCase(getAllPositions.fulfilled, (state, action) => {
                    //   alert('getAllPositions: Promise has been fulfilled !!!')  
                       state = {...state, fetchStatus: 'Succeeded',  List: action.payload };
                   //    console.log(`status state ${JSON.stringify(state.List)} `) 
                       return state;       
                 })
                 .addCase(getAllPositions.rejected, (state, action) => {
                       state = {...state, fetchStatus: 'Rejected',  List: [] };    
                       return state;       
                 })
      }      
            
   }
)


export const  allPositions = (state) => state.positions.List;
export const  positionData = (state) => state.positions.Profile;
export const  fetchStatus = (state) => state.positions.fetchStatus;
// export const { setStatus, setCurrentID } = statusSlice.actions;
export default positionsSlice.reducer;
