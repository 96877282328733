import {useEffect} from 'react';
import { Navigate, useRoutes, useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';


import './css/all.min.css';
import './css/style.css';
import './css/tally.css';
import './css/media-queries.css';
// import './css/vertical-nav-bar.css';
import './img/others/favicon.png';

// import SideBar from "./inc/vertical-nav-bar";

import {statistics, fetchStatistics} from './features/hr/employeesSlice';


// import "./inc/jquery-3.6.0.min"


// import Slide from "./inc/vertical-nav-bar-slide";

 // <script src="./inc/jquery-3.6.0.min.js"></script> 

 const Slidex = () => {
	   
		const sidebar = document.getElementById("closed") // document.getElementsByClassName("closed")[0];   //document.querySelector('.closed');    
		const hamburgerIcon = document.querySelector('.fa-bars');

		sidebar.classList.toggle("closed")

}



const Home = ({AuthData}) => {
   
  const dispatch = useDispatch();


   useEffect ( () => {

        const handleEsc  = (evt) => {
              if (evt.key === 'Escape') {
					    alert ('nice one')
			     }
			    
		  }
    	//   const handleSidebar = (evt) => {
		// 	//if (evt.click === 'click') {
		// 	//	  alert ('nice oneqq')
		// 		  sidebar.classList.toggle("closed");
		// 	//}
		  
	   //   }
	
		document.addEventListener('keyup', handleEsc);
		
		
		return () => {
			document.removeEventListener('keyup', handleEsc);
			// document.removeEventListener('keydown', handleEnterButton);
	 };

        
	}, [])

   
	
	const statistic = useSelector(statistics)


	useEffect ( () => {
         
		dispatch(fetchStatistics("Statistics"));
  
	}, [dispatch]) 

	



  
	
	
	
	
	{/* <div className='divMain'>	  <SideBar AuthData={AuthData} /> */}	 
	
	return (
       
		    		
			<main >
               
					<header>
						<i className="fas fa-bars" onClick={  () => Slidex() }> </i>
						{/* <i id="fa-bars" className="fas fa-bars"></i> */}
						<h2 className="text">Home</h2>
					</header>

					<section id="dashboard">

						<h3>Dashboard</h3>
						
						<div className="tally grid-wrapper">
							<div className="grid-item">
								<div className="tally-label">
									<div className="fas fa-user-tie"></div>
									<h4>Employees</h4>
								</div>
								{/* <p> <?php echo $guarantor->getTotalCurrentGuarantors(); ?></p> */}
								<p> {`All(${statistic.TotalEmps}) Active(${statistic.TotalEmps_Active}) Inactive(${statistic.TotalEmps_Inactive})`} </p>

								
							  
							</div> {/* .grid-item */}

							<div className="grid-item">
								<div className="tally-label">
									<div className="fas fa-user-tag"></div>
									<h4>Newly hired employees</h4>
									{/* <span> 180 days</span> */}
								</div>
								{/* <p><?php echo $borrower->getTotalCurrentBorrowers(); ?></p> */}
								<p> { statistic.TotalEmps_newlyhired} </p>
							</div> {/* grid-item  */}
							
							<div className="grid-item">
								<div className="tally-label">
									<div className="fas fa-wallet"></div>
									<h4>O.B</h4>
								</div>
								{/* <p><span>&#8369;</span> <?php echo number_format($cash_on_hand, 2, ".", ","); ?></p> */}
								<p> 50 </p>
							</div>  {/* .grid-item */}

							<div className="grid-item">
								<div className="tally-label">
									<div className="fas fa-vote-yea"></div>
									<h4>O.T Authorizations</h4>
								</div>
								{/* <p><span>&#8369;</span> <?php echo number_format($collections, 2, ".", ","); ?></p> */}
								<p> 300 </p>
							</div> {/* .grid-item  */}
							
							<div className="grid-item">
								<div className="tally-label">
									<div className="fas fa-crosshairs"></div>
									<h4>Applied Leaves</h4>
								</div>
								{/* <p><span>&#8369;</span> <?php echo number_format($total_uncollected_loans, 2, ".", ","); ?></p> */}
								<p> 300 </p>
							</div> {/* .grid-item  */}

							<div className="grid-item">
								<div className="tally-label">
									<div className="fas fa-file-invoice"></div>
									<h4>Unpaid Loans</h4>
								</div>
								{/* <p><span>&#8369;</span> <?php echo number_format($transaction->getTotalPaymentsToday(), 2, ".", ","); ?></p> */}
								<p> 10 </p>
							</div> {/* .grid-item */}
							
							<div className="grid-item">
								<div className="tally-label">
									<div className="fas fa-sign-language"></div>
									<h4>Loans Today</h4>
								</div>
								{/* <p><?php echo $loan->getTotalLoansToday(); ?></p> */}
								<p> 100 </p>
							</div> {/* .grid-item  */}
							
							<div className="grid-item">
								<div className="tally-label">
									<div className="fas fa-piggy-bank"></div>
									<h4>C.A Today</h4>
								</div>
								{/* <p><span>&#8369;</span> <?php echo number_format($savings, 2, ".", ","); ?></p> */}
								<p> 200 </p>
							</div>  {/* .grid-item  */}
							
							
							
							<div className="grid-item">
								<div className="tally-label">
									<div className="fas fa-exclamation-triangle"></div>
									<h4>Memos</h4>
								</div>
								<p>
									{/* <span>&#8369;</span> */}
									5
									{/* <?php echo number_format(($to_be_recovered < 0 ? 0 : $to_be_recovered), 2, ".", ","); ?> */}
								</p>
							</div>  {/* .grid-item  */}
							
					
						</div> {/* .grid-wrapper */}
					
					</section> {/* #dashboard  */}


					<section id="charts">

						<h3>Charts</h3>
					
					</section> {/* #charts  */}	

		</main>

		   
	
 
  )


}	


export default Home;


