
// use for user authentication purposes ...
import {createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Axios from 'axios';


export const getAllSeparationCodes =  createAsyncThunk("separationcodes/getAll",  async () => {
      
      let response = [];

      try {
            response = await Axios.get(`/api-uprising/separationcodes`)
                                  .then ((res) => res.data);
            
      } catch (error) {
            alert (`error ${error}`)
      }

      return response;

 });


const initialState = {
      List:         [],
      Profile:      {
                       SPCode:             '',
                       Attrision_Reason:   '',
                       Description:        '',
                       Notes:              '',
                    },
      fetchStatus:  'Idle'   // 'Idle' || 'Loading' || 'Succeeded' || 'Failed' 
};


const separationCodesSlice = createSlice (
      {
            name: 'separationCodes',
            initialState,  
            reducers:  {

            },
            
            extraReducers: (builder) => {
                  builder
                  .addCase(getAllSeparationCodes.pending, (state, action) => {
                        state = {...state, fetchStatus: 'Idle',  List: [] };    
                        return state;       
                  })
                  .addCase(getAllSeparationCodes.fulfilled, (state, action) => {
                       // alert('getAllSeparationCodes  ==> Promise has been fulfilled !!!')  
                        state = {...state, fetchStatus: 'Succeeded',  List: action.payload };
                     //   console.log(`getAllSeparationCodes ==> status state ${JSON.stringify(state)} `) 
                        return state;       
                  })
                  .addCase(getAllSeparationCodes.rejected, (state, action) => {
                        state = {...state, fetchStatus: 'Rejected',  List: [] };    
                        return state;       
                  })
            }      
                  
      }
)


export const  allSeparationCodes  =  (state) => state.separationCodes.List;
export const  separationCodeData  =  (state) => state.separationCodes.Profile;
export const  fetchStatus         =  (state) => state.separationCodes.fetchStatus;

export default separationCodesSlice.reducer;


 



