import { Navigate, useRoutes, useLocation } from 'react-router-dom';


import App from './App';

// layouts


import Home from './home';
// import SimpleLayout from './layouts/simple';

//
// import DashboardAppPage from './pages/DashboardAppPage';

// Main Menus
// import HrPage from './pages/HrPage';
// import TmsPage from './pages/TmsPage';
// import PmsPage from './pages/PmsPage';
// import MgmtPage from './pages/MgmtPage';
// import AdminPage from './pages/AdminPage';
// import MessagesPage from './pages/MessagesPage';
// import RemindersPage from './pages/RemindersPage';

// import BlogPage from './pages/BlogPage';
// import UserPage from './pages/UserPage';
// import LoginPage from './pages/LoginPage';
// import Page404 from './pages/Page404';
// import ProductsPage from './pages/ProductsPage';
// import Report_and_AnalyticsPage from './pages/LogoffPage';
// import LogoffPage from './pages/LogoffPage';



// HR Modules (Setup)
// import Branches from './pages/HrPages/Branches';
   import Departments from './Pages/Hr/Departments';
// import Sections from './pages/HrPages/Sections';
   import Status from './Pages/Hr/Status';
// import Positions from './pages/HrPages/Positions';
// import TypeofWarnings from './pages/HrPages/Typeofwarnings';
   import TypeofLeaves from './Pages/Hr/Typeofleaves';

   import Employees from './Pages/Hr/Employees';

// import Holidays from './pages/HrPages/Holidays';
// import Events from './pages/HrPages/Events';

import Leaves from './Pages/Hr/Leaves';
// import LeaveCreditBalances from './pages/HrPages/Leave-credit-balances';
// import Warnings from './pages/HrPages/Warnings';

// import Itineraries from './pages/HrPages/Itineraries';


// TMS Modules (Setup)
import Timecards from './Pages/Tms/Timecards';
import TimeAttendance from './Pages/Tms/TimeAttendance';
import Shifts from './Pages/Hr/Shifts';
import ChangeShifts from './Pages/Tms/ChangeShifts';
import ChangeRestday from './Pages/Tms/ChangeRestday';
import OTAuthorizations from './Pages/Tms/OTAuthorizations';


 
// PMS Modules (Setup)
// import TypeofAllowance from './pages/PmsPages/TypeofAllowance';
// import TypeofIncentive from './pages/PmsPages/TypeofIncentive';
// import TypeofDeduction from './pages/PmsPages/TypeofDeduction';
// import TypeofLoan from './pages/PmsPages/TypeofLoan';

// import SSSTable from './pages/PmsPages/SSS-Table';
// import PhilHealthTable from './pages/PmsPages/PhilHealth-Table';
// import PagIBIGTable from './pages/PmsPages/Pag-IBIG-Table';
// import WTaxTable from './pages/PmsPages/WTax-Table';

// import Allowances from './pages/PmsPages/Allowances';
// import Incentives from './pages/PmsPages/Incentives';
// import OtherAdditions from './pages/PmsPages/OtherAdditions';

// import SSSLoan from './pages/PmsPages/SSSLoan';
// import PagIBIGLoan from './pages/PmsPages/Pag-IBIGLoan';
// import OtherLoans from './pages/PmsPages/OtherLoans';

// import Deductions from './pages/PmsPages/Deductions';
// import OtherDeductions from './pages/PmsPages/OtherDeductions';



// ----------------------------------------------------------------------

const GetLastPartoftheUrl = () => ( useLocation().pathname.split('/').at(-1) )


export default function Router({AuthData}) {
  
  
   const routes = useRoutes([
      {
         path: '/',
         element:  <App />,
         
         children: [
                        
                        { element: <Navigate to="/home" />, index: true },
                        
                        { path: 'home', element: <Home /> },

                        // { path: 'hr',   element:  <Employees/> ,
                        { path: 'hr' ,

                                       children: [
                                                      { element: <Navigate to="/hr" />, index: true },
                                                      
                                                      { path: 'employees',           element:    <Employees status='active' /> },
                                                      
                                                      // { path: 'employees/inactive',         element:    <Employees status='inactive' /> },
                                                      // { path: 'employees/all',              element:    <Employees status='all' /> },
                                                      
                                                      // { path: 'branches',                   element:    <Branches /> },
                                                         { path: 'departments',                element:    <Departments /> },
                                                      // { path: 'sections',                   element:    <Sections /> },
                                                         { path: 'status',                     element:    <Status /> },
                                                      // { path: 'positions',                  element:    <Positions /> },
                                                      // { path: 'typeofwarnings',             element:    <TypeofWarnings /> },
                                                         { path: 'leave-type',                 element:    <TypeofLeaves /> },
                                                      
                                                      // { path: 'holidays',                   element:    <Holidays /> },
                                                      // { path: 'leaves',                     element:    <Leaves /> },
                                                      // { path: 'events',                     element:    <Events /> },
                                                         { path: 'leaves',                     element:    <Leaves /> },
                                                      // { path: 'leave-credit-and-balances',  element:    <LeaveCreditBalances /> },
                                                      // { path: 'warnings-and-suspensions',   element:    <Warnings /> },

                                                      // { path: 'itinerary',                  element:    <Itineraries /> },
                                                      { path: 'shifts',                  element:    <Shifts /> },

                                                      

                                                      
                                                   ]
                  
                        },
                        
                        // { path: 'tms',  element:    <TmsPage /> ,  
                         { path: 'tms',  
                                          children: [
                                                         { element: <Navigate to="/tms" />, index: true },
                                                         
                                                         { path: 'timecards',               element:    <Timecards /> },
                                                         { path: 'time-attendance',         element:    <TimeAttendance /> },
                                                         // { path: 'shifts',               element:    <Shifts /> },
                                                         { path: 'change-shifts',           element:    <ChangeShifts /> },
                                                         { path: 'change-restday',          element:    <ChangeRestday /> },
                                                         { path: 'o.t-authorizations/std',  element:    <OTAuthorizations type='std' /> },
                                                         // { path: 'ot-authorizations/adv',   element:    <OTAuthorizations /> },
                                                      ]
                        
                        },


                        // { path: 'pms',  element:    <PmsPage /> , 
                        //                   children: [
                        //                                  { element: <Navigate to="/pms" />, index: true },
                                                         
                        //                                  // { path: 'type-of-allowance',         element:    <TypeofAllowance /> },
                        //                                  // { path: 'type-of-incentive',         element:    <TypeofIncentive /> },
                        //                                  // { path: 'type-of-deduction',         element:    <TypeofDeduction /> },
                        //                                  // { path: 'type-of-loan',              element:    <TypeofLoan /> },

                        //                                  // { path: 'sss-table',                 element:    <SSSTable /> },
                        //                                  // { path: 'philhealth-table',          element:    <PhilHealthTable /> },
                        //                                  // { path: 'pag-ibig-table',            element:    <PagIBIGTable /> },
                        //                                  // { path: 'wtax-table/daily',          element:    <WTaxTable /> },
                        //                                  // { path: 'wtax-table/weekly',         element:    <WTaxTable /> },
                        //                                  // { path: 'wtax-table/semi-monthly',   element:    <WTaxTable /> },
                        //                                  // { path: 'wtax-table/monthly',        element:    <WTaxTable /> },

                        //                                  // // txCode !== ''? navigate (`/dashboard/pms/wtax-table/${txCode.toLowerCase()}`) : null


                        //                                  // { path: 'employee-additions/allowances',        element:    <Allowances /> },
                        //                                  // { path: 'employee-additions/incentives',        element:    <Incentives /> },
                        //                                  // { path: 'employee-additions/other-additions',   element:    <OtherAdditions /> },

                        //                                  // { path: 'employee-deductions/loans/sss-loan',            element:    <SSSLoan /> },
                        //                                  // { path: 'employee-deductions/loans/pag-ibig-loan',       element:    <PagIBIGLoan /> },
                        //                                  // // { path: 'employee-deductions/loans/other-loans/all',       element:    <OtherLoans /> },
                                                         
                        //                                  // { path: `employee-deductions/loans/other-loans/${GetLastPartoftheUrl()}`,       element:    <OtherLoans type= { GetLastPartoftheUrl() } /> },

                        //                                  // // { path: `employee-deductions/loans/other-loans/${GetLastPartoftheUrl()}`,       element:    <OtherLoans type = { GetLastPartoftheUrl() } /> },
                        //                                  // // navigate (`/dashboard/pms/employee-deductions/loans/other-loans/${loanCode.toLowerCase()}`) : null


                        //                                  // { path: 'employee-deductions/deductions',       element:    <Deductions /> },
                        //                                  // { path: 'employee-deductions/other-deductions', element:    <OtherDeductions /> },

                                                         
                        //                                  // { path: 'time-attendance',                       element:    <TimeAttendance /> },
                                             
                        //                         ]
                        // },


                        // { path: 'mgmt',          element:    <MgmtPage /> },
                        // { path: 'products',      element:    <ProductsPage /> },
                        // { path: 'messages',      element:    <MessagesPage /> },
                        // { path: 'reminders',     element:    <RemindersPage /> },
                        // { path: 'admin',         element:    <AdminPage /> },
                        // { path: 'blog',          element:    <BlogPage /> },
                        // { path: 'reports',       element:    <HrPage /> },
                        // { path: 'logoff',        element:    <LogoffPage /> },
                   ], 
      },

     
      {
         path: 'login',
         // element: <LoginPage />,
      },
      
      
      {  
         path: 'logoff', 
         // element: <LogoffPage /> 
      },


      {
         // element: <SimpleLayout />,
         // children: [
         //             { element: <Navigate to="/dashboard/app" />, index: true },
         //             { path: '404', element: <Page404 /> },
         //             { path: '*', element: <Navigate to="/404" /> },
         //           ],
      },

      
     

      {
         path: '*',
         element: <Navigate to="/404" replace />,
      },
      
      

  ]);



  return routes;


}
