
import React from 'react';
import ReactDOM from 'react-dom/client';

import { BrowserRouter } from "react-router-dom";

import {Provider} from 'react-redux';
import {store} from './store';

import { HelmetProvider } from 'react-helmet-async';

// theme
import ThemeProvider from './theme';

// components
import { StyledChart } from './components/chart';
import ScrollToTop from './components/scroll-to-top';

// routes
import Router from './routes';


import './index.css';
import * as serviceWorker from './serviceWorker';


// import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));


root.render(
  
    <React.StrictMode>
        
        <HelmetProvider>
            <Provider store = {store} >
                <BrowserRouter>
                    <ThemeProvider>
                        <ScrollToTop />
                        <StyledChart />
                        <Router /> 
                    </ThemeProvider>
                </BrowserRouter>
            </Provider>
        </HelmetProvider>

    </React.StrictMode>
    
);



// root.render(
//     <React.StrictMode>
//        <App />
//     </React.StrictMode>
//   );



// If you want to enable client cache, register instead.
serviceWorker.unregister();


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
 // reportWebVitals();
