
import PropTypes from 'prop-types';
import {useEffect, useState}  from 'react';
import {useDispatch} from 'react-redux';

import swAlert from 'sweetalert2';



// @mui
import { styled, alpha } from '@mui/material/styles';
import { Toolbar, Tooltip, IconButton, Typography, OutlinedInput, InputAdornment } from '@mui/material';

// component
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

import { deleteRecord as deleteRecEmployees,  } from '../../../features/hr/employeesSlice';



const StyledRoot = styled(Toolbar)(({ theme }) => ({
      height: 96,
      display: 'flex',
      justifyContent: 'space-between',
      padding: theme.spacing(0, 1, 0, 3),
}));

const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
       width:         240,
       transition:    theme.transitions.create(['box-shadow', 'width'], {
       easing:        theme.transitions.easing.easeInOut,
       duration:      theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': {
       width:         320,
       boxShadow:     theme.customShadows.z8,
  },
  '& fieldset': {
       borderWidth: `1px !important`,
       borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
}));


// ----------------------------------------------------------------------

EmployeeListToolbar.propTypes = {
                      list: PropTypes.string,
                      numSelected: PropTypes.number,
                      filterName: PropTypes.string,
                      onFilterName: PropTypes.func,
                  };




export default function EmployeeListToolbar({ list, numSelected, filterName, onFilterName }) {
  
  
  const dispatch  = useDispatch();
  const [isSelected, setSelected] = useState(0)

  // alert (`numSelected : ${numSelected}`)

  useEffect( () => {
     setSelected(numSelected)
  }, [numSelected])
  
  


  function DeleteRecord() {
  
    // warning, error, success, info, question


    if (list.length === 0) {
        
        swAlert.fire({
                        title: 'Record Status',
                        text:  'Record is not yet selected! Just select the record and try again',
                        icon:  
                        'info',
                        confirmButtonText: 'OK',
                    });
    } 
    
    
    if (list.length > 0 ) {
    
              swAlert.fire({
                            title: 'Are you sure?',
                            text: "You won't be able to revert this!",
                            icon: 'warning',
                            showCancelButton: true,
                            confirmButtonColor: '#3085d6',
                            cancelButtonColor: '#d33',
                            confirmButtonText: `Yes, delete (${list.length}) it!`
                            })
                       .then((result) => {
                                            if (result.isConfirmed) {
                                                
                                                dispatch (deleteRecEmployees(list) )
                                                setSelected(0)    

                                                swAlert.fire(
                                                              'Deleted!',
                                                              `Record${list.length>1? 's':''} has been deleted.`,
                                                              'success'
                                                )
                                            }
                       })

    }


  }

  // alert (`isSelected : ${isSelected}`)


  return (
    
    <StyledRoot
          sx={{
            ...(numSelected > 0 && {
                    color:   'primary.main',
                    bgcolor: 'primary.lighter',
              }),
          }}
    >
          {
            
            numSelected > 0 && Boolean(isSelected) ? (
                <Typography component="div" variant="subtitle1">
                    {numSelected} selected
                </Typography>
            ) : (
                <StyledSearch
                    value =          {filterName}
                    onChange =       {onFilterName}
                    placeholder =    "Search name..."
                    startAdornment = {
                                        <InputAdornment position="start">
                                            <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                                        </InputAdornment>
                                    }
                />
            )
          }

          {
            numSelected > 0 && Boolean(isSelected)? (
                <Tooltip title="Delete">
                    <IconButton onClick={ () => DeleteRecord() }> 
                        <Iconify icon="eva:trash-2-fill" />
                    </IconButton>
                </Tooltip>
            ) : (
                <Tooltip title="Filter list">
                  <IconButton>
                    <Iconify icon="ic:round-filter-list" />
                  </IconButton>
                </Tooltip>
          )
          }
    
    </StyledRoot>

  );

}
