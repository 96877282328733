import {useEffect} from 'react';
import { Link, Navigate, useRoutes, useLocation, Outlet, useNavigate} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import '../css/media-queries.css';
import  "../css/vertical-nav-bar.css";
import '../css/style.css';
//  import  "./jquery-3.6.0.min.js";

import {Employees, } from '../Pages/Hr';


const HrNavigationMenu_xxx = () => (
     
	<div style={{ width: '90%', position: 'fixed', border: 0, }} >
		 {/* <Navbar /> */}
		 

		 {/* <BurgerMenu /> */}

		 <br/>
		 <br/>
		 
		 

		 {useLocation().pathname === '/hr'? <Employees /> : null}
		 {/* {useLocation().pathname === '/dashboard/hr/departments'? <Departments /> : null}
		 {useLocation().pathname === '/dashboard/hr/sections'? <Sections /> : null}
		 {useLocation().pathname === '/dashboard/hr/status'? <Status /> : null}
		 {useLocation().pathname === '/dashboard/hr/positions'? <Positions /> : null}
		 {useLocation().pathname === '/dashboard/hr/typeofwarnings'? <TypeofWarnings /> : null}
		 {useLocation().pathname === '/dashboard/hr/typeofleaves'? <TypeofLeaves /> : null}
		  */}
		 
		 {/* {
			alert (useLocation().pathname)
		 } */}

		 {/* <Routes>
				  <Route path='/dashbord/hr' element={ <Employees /> }/>
				  <Route path='/dashbord/hr/travel' element={Travel}/>
				  <Route path='/dashbord/hr/OB' element={OB}/>
		 </Routes>  */}
		 
	</div>

)


const Slidex = () => {
	   
	const sidebar = document.getElementById("closed") // document.getElementsByClassName("closed")[0];   //document.querySelector('.closed');    
	const hamburgerIcon = document.querySelector('.fa-handshake');  // fa-handshake , fa-bars

	sidebar.classList.toggle("closed") 

}


const Slide = () => {
	
	const handleEvent = (evt) => {
		let arrowParent = evt.target.parentElement.parentElement; //selecting main parent of arrow
		arrowParent.classList.toggle("showMenu")
	}


	let arrows = document.querySelectorAll(".fa-chevron-down"); 
		
		for (let i = 0; i < arrows.length; i++) {
		   arrows[i].addEventListener("click", evt => {handleEvent(evt)} , {once: false}) 
		}
}



const HandleonDropdownMenu = (evt) => {
	   
	   let arrowParent = evt.target.parentElement.parentElement; //selecting main parent of arrow
	   arrowParent.classList.toggle("showMenu")

}

const HandleonDropdownMenu_inner = (evt) => {
	   
	let arrowParent = evt.target.parentElement.parentElement; //selecting main parent of arrow
	arrowParent.classList.toggle("showMenu")

}

const SideBar = ({AuthData}) => {
	
	useEffect (() => {
 	//  Slide()
	}, [])

	 


   
	


   return (
      
		   <>
              <nav id="closed" className="closed">

							<h1>
								<i className="fas fa-handshake" onClick={()=> Slidex() }> </i>
								<span><Link to="/dashboard" style={{ color: 'white',  textDecoration: 'none' }} > Hr Connect </Link></span>
							</h1>
			

							<ul className="nav-links">
									
									<li>
											<Link to='/home'>
												<i className="fas fa-home"></i>
												<span className="link-name">Home</span>
											</Link>
											<ul className="sub-menu blank">
												<li><Link className="link-name" to='/home'> Home </Link> </li>
											</ul>
									</li>


									
									<li>
											<div className="parent-menu">
											   <Link to='hr'>
													<i className="fas fa-users"></i>
													<span className="link-name">Hr</span>
												</Link>
												<i className="fas fa-chevron-down" onClick={HandleonDropdownMenu}></i>
											</div> {/* .parent-menu */}
			
											<ul className="sub-menu">
												<li><Link className="link-name" to="/hr">Human Resources</Link></li>
												<li><Link to='/hr/employees'>Employees</Link></li>
												<li><Link to='/hr/departments'>Department</Link></li>
												<li><Link to='/hr/status'>Working Status</Link></li>
												
												
												
												<li><Link to='/hr/leave-type'>Leave Type</Link></li>
												<li><Link to='/hr/shifts'>Shifts</Link></li>
												
												
												<hr className='hr'/>
												
												<li> 
													   <div className="parent-menuq">
															<Link to='forms'>
																<span >Forms</span>
															</Link>
															<i className="fas fa-chevron-right" onClick={HandleonDropdownMenu_inner}></i>
														</div> { /* .parent-menu  */}
						
														<ul className="sub-menu2">
															<li><Link to="/hr/leaves">Leave Application</Link></li>
															<li><Link to='/hr/employees'>O.B</Link></li>
														</ul> {/* .sub-menu */}
												
												</li>	

											</ul> {/* .sub-menu */}
			
									</li>
			
			
									<li>
											<div className="parent-menu">
												<a href="transactions.php">
													<i className="fas fa-layer-group"></i>
													<span className="link-name">Time Keeping</span>
												</a>
												<i className="fas fa-chevron-down" onClick={HandleonDropdownMenu}></i>
											</div>  {/* .parent-menu */}
			
			
											<ul className="sub-menu">
												<li><a className="link-name" href="transactions.php">Time Keeping</a></li>
												
												<li><Link to="/tms/timecards">Timecards</Link></li>
												<li><Link to="/tms/time-attendance">Time-Attendance</Link></li>
												<li><Link to="/tms/change-shifts">Change Shifts</Link></li>
												<li><Link to="/tms/change-restday">Change Restday</Link></li>
												<li><Link to="/tms/o.t-authorizations/std">Overtime Authorization</Link></li>

											</ul> {/* .sub-menu */}
			
									</li>
			
									
									<li className='disabled'>
											<div className="parent-menu">
												<a href="payroll.php">
													<i className="fas fa-cash-register"></i>
													<span className="link-name">Payroll</span>
												</a>
												<i className="fas fa-chevron-down" onClick={HandleonDropdownMenu}></i>
											</div>  {/* .parent-menu */}
			
											<ul className="sub-menu">
												<li><a className="link-name" href="payroll.php">Payroll</a></li>
												<li><a href="payroll.php#profits">Profits</a></li>
												<li><a href="payroll.php#principal-summation">Principal Summation</a></li>
												<li><a href="payroll.php#interest-summation">Interest Summation</a></li>
												<li><a href="payroll.php#shares">Shares</a></li>
												<li><a href="payroll.php#honorarium">Honorarium</a></li>
											</ul> {/* .sub-menu  */}
			
									</li>
			
									
									<li>
											<a href="cycle.php">
												<i className="fas fa-history"></i>
												<span className="link-name">Reports</span>
											</a>
											<ul className="sub-menu blank">
												<li><a className="link-name" href="cycle.php">Reports</a></li>
											</ul>
									</li>
			
									<li>
											<div className="profile-details">
													<a href="user-settings.php">
														<div className="profile-content">
															{/* <img src=" {` ${echo $profile_picture`}" alt="Avatar"> */}
														</div> {/* .profile-content */}
			
			
			
														<div className="name-position">
															<div className="username"> { AuthData?.Username ? `Username: ${AuthData.Username}` : null } </div>
															<div className="position"> { AuthData?.GrpCode ? `Access Priveleges: ${AuthData.GrpCode}` : null } </div>
														</div> {/* .name-position */}
			
													</a>
													
													<i className="fas fa-sign-out-alt" ></i>
													{/* <i className="fas fa-sign-out-alt" onClick={ ()=> document.getElementById("navX").classList.toggle("closed")  }></i> */}
											</div>  {/* .profile-details */}
			
									</li>
			
							</ul> {/* .nav-links  */}
			
					</nav>

					
					< Outlet />

			</> 
  
   
		 			
	)
	 
} ;


export {Slide};
export default SideBar;

