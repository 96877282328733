
import {configureStore} from '@reduxjs/toolkit';

import userAuthReducer from './features/users/authsSlice';
import employeeReducer from './features/hr/employeesSlice';
import departmentReducer from './features/hr/departmentsSlice';
import statusReducer from './features/hr/statusesSlice';
import positionReducer from './features/hr/positionsSlice';
import shiftReducer from './features/hr/shiftsSlice';
import separationCodesReducer from './features/hr/separationCodesSlice';

import timecardReducer from './features/tms/timecardsSlice';



export const store = configureStore ({
      reducer: {
                  userAuthentication:     userAuthReducer,
                  employees:              employeeReducer,
                  departments:            departmentReducer,
                  status:                 statusReducer,
                  positions:              positionReducer,
                  shifts:                 shiftReducer,
                  separationCodes:        separationCodesReducer,

                  timecards:              timecardReducer,
               
               },
               middleware: getDefaultMiddleware => getDefaultMiddleware({
                  serializableCheck: false
                })
});

