
// use for user authentication purposes ...
import {createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Axios from 'axios';

 
export const fetchDepartments =  createAsyncThunk("departments/getAll",  async () => {
    
     let response = [];

      try {
            response = await Axios.get(`/api-uprising/departments`)
                                  .then ((res) => res.data);
            
      } catch (error) {
            console.error(error);
      }

     // alert (`nice thunkc ${Object.keys(response)}`)
     // alert (`JSON.stringify(data) ${JSON.stringify(response)}`)

      return response;

 });

 
 const initialState = {
      List:       [],
      Profile:    {
                    DeptID:           '',
                    Department:       '',
                    Description:      '',
                    Notes:            '',
                    CompanyID:        '',
                  },
      fetchStatus:                 'Idle'   // 'Idle' || 'Loading' || 'Succeeded' || 'Failed' 
} ;    


const departmentsSlice = createSlice (
   {
      name: 'departments',
      initialState,
      reducers:  {
            
                     setDepartment:    (state, action) => {
                                             
                                             // state = {...state, Username: action.payload.Username, Password: action.payload.Password,  isValidate: getValidate(action.payload)}; 
                                             // state = {...state, isValidate: action.payload.isValidate}

                                             console.log(`action.payload ${Object.keys(action.payload)}`)

                                            //  console.log(`action.payload ${Object.values(action.payload)}`)
                                             
                                             // state = {...state, ...action.payload};
                                             state = {...state, ...action.payload};
                                             console.log(`state ${state.DeptID}`)
                                             return state;
                                    },

                        setCurrentID:    (state, action) => {
                              state.Profile = {...state.Profile, DeptID: action.payload.DeptID};
                              console.log(`state currentID ${state.Profile.DeptID}`)
                              return state;
                        },            
                        
                       
      },
      
      extraReducers: (builder) => {
               builder
                 .addCase(fetchDepartments.pending, (state, action) => {
                       state = {...state, fetchStatus: 'Idle',  List: [] };    
                       return state;       
                 })
                 .addCase(fetchDepartments.fulfilled, (state, action) => {
                       // console.log(`fetchDepartments's Promise has been fulfilled !!!`)  
                       state = {...state, fetchStatus: 'Succeeded',  List: action.payload };
                       return state;       
                 })
                 .addCase(fetchDepartments.rejected, (state, action) => {
                       state = {...state, fetchStatus: 'Rejected',  List: [] };    
                       return state;       
                 })
      }      
            
   }
)


export const  allDepartments = (state) => state.departments.List;
export const  departmentData = (state) => state.departments.Profile;
export const  fetchStatus = (state) => state.departments.fetchStatus;
export const { setDepartment, setCurrentID } = departmentsSlice.actions;
export default departmentsSlice.reducer;
