
// use for user authentication purposes ...
import {createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Axios from 'axios';


export const getAllStatus =  createAsyncThunk("status/getAll",  async () => {
      
      let response = [];

      try {
            response = await Axios.get(`/api-uprising/status`)
                                  .then ((res) => res.data);
            
      } catch (error) {
            // console.error(error);
            alert (`error ${error}`)
      }

     // alert (`nice thunkc ${Object.keys(response)}`)
     // alert (`JSON.stringify(getAllStatus) ${JSON.stringify(response)}`)

      return response;

 });


 const initialState = {
      List:       [],
      Profile:    {
                    StatusID:         '',
                    Status:           '',
                    Description:      '',
                    AllowLeave:        false,
                    ComputeLegal:      false,
                    ComputeSpecial:    false,
                  },
      fetchStatus:     'Idle'   // 'Idle' || 'Loading' || 'Succeeded' || 'Failed' 
};



const statusSlice = createSlice (
   {
      name: 'status',
      initialState,
      reducers:  {
            
                     setStatus:    (state, action) => {
                                             state = {...state, fetchStatus: action.payload};
                                             return state;
                                    },

                     setCurrentID:  (state, action) => {
                                              state.Profile = {...state.Profile, StatusID: action.payload.StatusID};
                                          return state;
                                    },                
      },
      
      extraReducers: (builder) => {
               builder
                 .addCase(getAllStatus.pending, (state, action) => {
                       state = {...state, fetchStatus: 'Idle',  List: [] };    
                       return state;       
                 })
                 .addCase(getAllStatus.fulfilled, (state, action) => {
                       state = {...state, fetchStatus: 'Succeeded',  List: action.payload };
                       return state;       
                 })
                 .addCase(getAllStatus.rejected, (state, action) => {
                       state = {...state, fetchStatus: 'Rejected',  List: [] };    
                       return state;       
                 })
      }      
            
   }
)


export const  allStatus = (state) => state.status.List;
export const  statusData = (state) => state.status.Profile;
export const  fetchStatus = (state) => state.status.fetchStatus;
export const { setStatus, setCurrentID } = statusSlice.actions;
export default statusSlice.reducer;
