
import React , {useState, useRef, useEffect} from 'react';


import { useSelector, useDispatch } from 'react-redux';







import { authenticateUser,fetchUserPrivileges, fetchUserRecordLevelRights, validateUser, getValidate, userAuthData } from './features/users/authsSlice';


// import logo from './logo.svg';
import './App.css';
import './css/style.css';
import './css/forms.css';
import './css/login.css';

// // routes
// import Router from './routes';

// import  "./inc/jquery-3.6.0.min";
import SideBar from "./inc/vertical-nav-bar";
import Home from './home';



// import "./inc/jquery-3.6.0.min"
// import Slide from "./inc/vertical-nav-bar-slide";




const showPassword = (evt) =>  {
  
      let x = document.getElementById("password");
  
      if (x.type === "password" && evt.target.value === "on") {
          x.type = "text";
      } else {
          x.type = "password";
      }

}






const Login = ()=> {
    
    const userRef = useRef();
    const errRef = useRef();

    const [User, setUser] = useState('');
    const [Pwd, setPwd] = useState('');
    const [errMsg, setErrMsg] = useState('');

    const [AccessType, setAccessType] = useState("ADMIN");
    const [AccessCode, setAccessCode] = useState("");
    const [fetchProgressValue, setfetchProgressValue]  = useState (0);
    
   // const navigate = useNavigate();


    useEffect(() => {
        userRef.current.focus();
    }, []); 

    useEffect(() => {
        setErrMsg(''); 
    }, [User, Pwd]) 


    useEffect(() => {

        const handleEsc = (event) => {
            
                if (event.key === 'Escape') {
                    setUser("")
                    setPwd("")
                    setErrMsg("")

                    // document.getElementsByName('AccessCode').value = ""
                    // document.getElementsByName('Username').value = ""
                    // document.getElementsByName('Password').value = ""

                };
                
        };

        const handleEnterButton = (event) => {

                if (event.keyCode === 13) {
                    // Note that this doesn't honour tab-indexes

                    event.preventDefault();

                    // Isolate the node that we're after
                    const currentNode = event.target;

                    // find all tab-able elements: 'audio, button, canvas, details, iframe, input, select, summary, textarea, video, [accesskey], [contenteditable], [href], [tabindex]'
                    const allElements = document.querySelectorAll('audio, canvas, iframe, input, button, a, area, object, select, details, summary, textarea, a[href], [accesskey], [contenteditable], [tabindex]');

                    // Find the current tab index.
                    const currentIndex = [...allElements].findIndex(el => currentNode.isEqualNode(el))

                    // focus the following element
                    const targetIndex = (currentIndex + 1) % allElements.length;
                    allElements[targetIndex].focus();  
            }
        }

        document.addEventListener('keyup',  handleEsc, );
        document.addEventListener('keydown',  handleEnterButton, );
        

        return () => {
            document.removeEventListener('keyup', handleEsc);
            document.removeEventListener('keydown', handleEnterButton);
        };

    }, []);

    
    
    const tmpAuthData = useSelector (userAuthData) ;

    const [authData, setAuthData] = useState({isValidate: false, UsrCode: '', GrpCode: ''}); // useSelector (userAuthData) ;

    const dispatch = useDispatch();

    useEffect ( () => {

            setAuthData(tmpAuthData);

    }, [tmpAuthData, dispatch])

    
    const [isValidatePass, setValidatePass] = useState(false);




    const OnValidatePass  = (evt) => {
        
        evt.preventDefault();

        // GRP01 === ADMIN : GRP05 === COOORDINATOR
        dispatch ( authenticateUser ( {Username: User, Password: Pwd, isAdminAccess: AccessType === 'ADMIN',  isValidate: false}  ) )      
    
        // if (isValidate === true ) {
        if (authData.isValidate === true ) {
                        
                    // dispatch ( fetchUserPrivileges({GrpCode: authData.GrpCode, setfetchProgressValue}) ) ;     
                    // dispatch ( fetchUserRecordLevelRights(USRCode) ) ;     

                    setUser('');
                    setPwd('');
                    setValidatePass(true);
                    
                    
        } else {
                    // alert('not valid')
                    setValidatePass(false);
                    setErrMsg('Invalid User!');
                    
                    
        }

    }

    
    useEffect ( () => {
        
        if (authData.isValidate) {
            dispatch ( fetchUserPrivileges({GrpCode: authData.GrpCode, setfetchProgressValue}) ) ; 
            dispatch ( fetchUserRecordLevelRights({UsrCode: authData.UsrCode, setfetchProgressValue}) ) ; 
        }   

    }, [dispatch])

    // }, [authData.isValidate, dispatch])


    
    return ( 
           
        
        Boolean(authData.isValidate) === true ? 
           
             // <Router AuthData={tmpAuthData} />
            
            // <Home AuthData={tmpAuthData} />
             < SideBar  AuthData={tmpAuthData} />
            
              // navigate ("/dashboard")
            // <Provider store = {store} >
            //     <HelmetProvider>
            //         <BrowserRouter>
            //             <ThemeProvider>
            //                 <ScrollToTop />
            //                 <StyledChart />
            //                 <Router AuthData={tmpAuthData} />
            //             </ThemeProvider>
            //         </BrowserRouter>
            //     </HelmetProvider>
            // </Provider>

        
        : 
       
           <div className="login-container">
             <>
                 <div className="headline"> 
                     HR Connect ... 
                 </div>
             
                 {/* <form action="src/process-login.php" method="post"> */}
                 
                 

                 <form onSubmit={ (evt) => OnValidatePass (evt) }>
                     
                     <div className="form-group">
                         {/* <input type="email" name="email" ref={userRef} placeholder="email" value={User} onChange = { (evt) => setUser(evt.target.value) }  required /> */}
                         <input type="text" name="email" ref={userRef} placeholder="username" value={User} onChange = { (evt) => setUser(evt.target.value) }  required />
                     </div>  
                     
                     <div className="form-group">
                         <input type="password" id="password" name="password" placeholder="password" value={Pwd} className="password"  onChange={ (evt) => setPwd (evt.target.value) }required />
                     </div>  

                     <div className="show-password">
                         <input id="show-password" type="checkbox" onClick= {showPassword }/>
                         <label htmlFor="show-password" > show password </label>
                     </div> 

                     <button type="submit" name="submit" className="btn"> login </button>

                     {
                     Boolean(isValidatePass) ===  false? <p ref = {errRef} aria-live='assertive'> <b><font size={10} color='red'> {errMsg} </font> </b> </p> : null
                     }
                     
                 </form>
            </>  
         
           </div> // <!-- login-container -->
   )

}




const App = () =>  {
    
  return (
          <div className="App">
               < Login />
          </div>
         );
}


export default App;
