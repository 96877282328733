 import React, {useState, useEffect, useRef} from 'react';
 
 import Axios from 'axios';

 import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, 
          Button, Box,  Tab, Paper, TextField, 
          MenuItem, InputLabel,
          Checkbox,
          Grid, 
          FormGroup, 
          FormControl, FormLabel, RadioGroup as MUIRadioGroup, FormControlLabel, Radio, 
          ThemeProvider, useTheme, createTheme,Typography,
          TableContainer, Table, TableBody, TableHead, TableRow, TableCell
          
        } from '@mui/material';


import { styled as styledMui } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';


 import IconButton from '@mui/material/IconButton';
 import CloseIcon from '@mui/icons-material/Close';

 import {TabContext, TabList,TabPanel} from '@mui/lab';
 import Stack from '@mui/material/Stack';


 import FavoriteIcon from '@mui/icons-material/Favorite' 
 // import Typography from 'src/theme/overrides/Typography';
 
 import styled from '@emotion/styled'
  
 import { useForm, Form,
          TxtField, 
          CheckBox as CheckBox1,
          DatePicker, 
          DateTimePicker, DateTimePickerStaticLandscape,
          Select, 
          RadioGroup
        } from '../../components/controls' 

import PhotoX from "./Photo/Laura.jpg";  // launch-101.png




const Div = styled.div({
   color: 'red'
})

 // use this to apply your own customized theme.
 const theme = createTheme({
      palette:  {
         mode: 'light',
         primary: {
             main: "#D433FF",
         }
      },
      Typography: {
           h1:  {
              fontSize: "1000px",
           }
      }

})

const H1 = styled.h1(
      {
        fontSize: 14,
      },
      props => ({ color: props.color })
)    






// ====================================================================================================================================================================================

                              


const Profile = ({data, setNewProfile}) => {
           
         const [Open, setOpen] = useState(true);
  
         function onCloseX(isXbutton) {
            setOpen(false); 
            if (isXbutton) {setNewProfile()}
         }   
        


         return (
            //      <ThemeProvider theme = {theme} >
                        <Paper>
                              
                              <Dialog PaperProps={{ style: { borderRadius: 10 } }}
                                      open = {Open} 
                                      onCLose = {false} 
                                      fullwidth maxWidth = {'md'} 
                              >
                              <DialogTitle id='dialog-title'> {`Employee Profile [ ${data.Mode} ]`} 
                              
                                    <IconButton
                                          aria-label="Close"
                                          onClick={() => onCloseX(true) }
                                          sx={{
                                                position: 'absolute',
                                                right: 8,
                                                top: 8,
                                                color: (theme) => theme.palette.grey[500],
                                          }}
                                          >
                                          <CloseIcon />
                                    </IconButton>
                                                
                              </ DialogTitle>
                              
                              {/* <DialogContent style={{ height: '100%', overflow: "hidden"  }}  > */}
                              <DialogContent style={{ height: '100%', overflow: "hidden"  }}>
                                    <DialogContentText id='dialog-description'> 
                                          {/* Employee Profile [New] */}
                                    </DialogContentText>
                                    
                                      < EProfile /> 
                                    
                              </DialogContent>    

                              
                              <DialogActions sx={{marginTop: '-15px', marginBottom: '5px', marginRight: '25px',}} >
                                    <Button autoFocus onClick={ () => setOpen(true) } sx={{backgroundColor: 'light', color: 'darkblue', borderColor: 'darkblue', }} variant='outlined'> Save & New </Button>    
                                    <Button onClick={ () => onCloseX(true) } sx={{backgroundColor: 'light', color: 'darkblue', borderColor: 'darkblue', }} variant='outlined'> Save & Close </Button>    
                                    <Button onClick={ () => onCloseX(true) } sx={{backgroundColor: 'light', color: 'darkblue', borderColor: 'darkblue', }} variant='outlined'> Cancel </Button>    
                              </DialogActions>

                              </Dialog>
                        </Paper>        
                  // </ThemeProvider>
                );
};


// ====================================================================================================================================================================================

function a11yProps (index) {
    
    return {
          id:              `full-width-tab-${index}`,
         'aria-controls':  `full-width-tabpanel-${index}`,
    };
  }


// ====================================================================================================================================================================================

const EProfile = () => {
       
    const [value, setValue] =  useState(0);
      
    const handleChange = (evt) => {
      
      setValue (evt.target.value);
      
    }
      
    

    return (
        
        <Box sx={{ borderBottom: 1, borderBottomWidth: '8px', borderColor: 'divider',  bgcolor: 'background.paper', width: '865px', height: '830px', }}   >
                 
                  <TabContext value = { Number(value) } >
                      <Box sx={{borderBottom: 4, borderColor: 'divider', width: '97%', marginLeft: '5px',  marginBottom: '-20px',   }}>
                           
                           <TabList
                                aria-label='Employee Profile'     
                                onClick={ handleChange }
                                indicatorColor="secondary"
                                TabIndicatorProps={{ sx: {backgroundColor: 'orange', height: 4} }}
                                textColor='inherit' 
                                variant='scrollable'
                                sx={{  "& button": {borderTopLeftRadius: 8, borderTopRightRadius: 8, backgroundColor: 'darkblue' }, 
                                       "& button:hover": {backgroundColor: 'blue'},
                                       "& button:focus": {backgroundColor: 'darkblue'},
                                       "& button:active": {backgroundColor: 'cyan'},
                                       "& button:Mui-selected": {backgroundColor: 'darkblue'},
                                       
                                 }}

                              // title= 'Tooltiptext'
                              
                            >

                               {/* <Tab label='Employee Information' {...a11yProps(0)}  Value='0' icon={<FavoriteIcon/> } iconPosition='start' /> */}
                               <Tab label='Employee Information' {...a11yProps(0)}  Value='0' style={{color: 'orange', fontSize: '12px', padding: 15, margin: 1, }} />
                               <Tab label='Personal Information' {...a11yProps(1)}  Value='1' style={{color: 'orange', fontSize: '12px', padding: 15, margin: 1, }} />
                               <Tab label='Employee Rate'        {...a11yProps(2)}  Value='2' style={{color: 'orange', fontSize: '12px', padding: 15, margin: 1, }} />
                               <Tab label='Miscellaneous'        {...a11yProps(3)}  Value='3' style={{color: 'orange', fontSize: '12px', padding: 15, margin: 1, }} />
                               <Tab label='Attachment'           {...a11yProps(4)}  Value='4' style={{color: 'orange', fontSize: '12px', padding: 15, margin: 1, }} />
                           </TabList>

                      </Box>
                      
                      <TabPanel value={0} >  
                         <Box border={4} color='gray' padding={1.5} sx={{ marginLeft: '-15px', }}  >
                           <EmployeeInformation />  
                         </Box>
                      </TabPanel>
                      
                      <TabPanel value={1}>  
                         <Box border={4} color='gray' padding={1.5} sx={{ marginLeft: '-15px', }}  >
                             <PersonalInformation /> 
                         </Box>   
                      </TabPanel>
                      
                      <TabPanel value={2}>  
                         <Box border={4} color='gray' padding={1.5} sx={{ marginLeft: '-15px', }}  >
                             <EmployeeRates />        
                         </Box>    
                      </TabPanel>

                      <TabPanel value={3}> 
                         <Box border={4} color='gray' padding={1.5} sx={{ marginLeft: '-15px', }}  >
                            <Miscellaneous />           
                         </Box> 
                        </TabPanel>

                      <TabPanel value={4}>  
                         <Box border={4} color='gray' padding={1.5} sx={{ marginLeft: '-15px', }}  >
                             <Attachments />                
                         </Box>
                      </TabPanel>
                  
                  </TabContext>   
              
            </Box>

          )

 
 };

// ====================================================================================================================================================================================

const EmployeeInformation = () => {
    

    const [initialValue, setInitialValue] = useState({
                  EmployeeID:       '',
                  Lastname:         '',
                  Firstname:        '',
                  Middlename:       '',
                  Gender:           'Male',
                  Birthdate:        new Date(),
                  Hiredate:         new Date(),
                  Payperiod:        'Semi-Monthly',
                  Deductionperiod:  'Semi-Monthly',
                  Department:       '',
                  Active:           true,
                  PosID:            '',
                  DeptID:           '',
                  StatusID:         '',
                  ShiftID:         '',
    });

     
    const {
             values, 
             setValues,
             onInputChange,
          } = useForm(initialValue);



    

    // use this to apply your own customized theme.
    const theme = createTheme({
           palette:  {
              mode: 'dark',
              primary: {
                  main: "#D433FF",
              }
           },
           Typography: {
                h1:  {
                   fontSize: "1000px",
                }
           }

    })

    const [LstPostions, setLstPositions] = useState([]);
    const [LstDepartments, setLstDepartments] = useState([]);
    const [LstWorkingStatus, setLstWorkingStatus] = useState([]);
    const [LstShifts, setLstShifts] = useState([]);

    const getPositions = () => {

      return LstPostions;

    }
    const getDepartments = () => {

      return LstDepartments;

    }
    
    const getWorkingStatus = () => {

      return LstWorkingStatus;

    }

    const getShifts = () => {

      return LstShifts;

    }

   
     // Position
     useEffect ( () => {
                 
      Axios.get('http://localhost:8800/api/positions')
             .then ((res) =>  {
                  setLstPositions(res.data)
                   // console.log (`res.data:  ${res.data}`) 
               })
             .catch( (err) => {
                  setLstPositions([])  
                   console.log (err);
                   alert(err)
               });
         
    }, []) 

    // Departments
    useEffect ( () => {
                 
      Axios.get('http://localhost:8800/api/departments')
             .then ((res) =>  {
                  setLstDepartments(res.data)
                   // console.log (`res.data:  ${res.data}`) 
               })
             .catch( (err) => {
                  setLstDepartments([])  
                   console.log (err);
               });
         
    }, []) 

    // Status
    useEffect ( () => {
                 
      Axios.get('http://localhost:8800/api/status')
             .then ((res) =>  {
                  setLstWorkingStatus(res.data)
                   // console.log (`res.data:  ${res.data}`) 
               })
             .catch( (err) => {
                  setLstWorkingStatus([])  
                   console.log (err);
                   alert(err)
               });
         
    }, []) 

     // Shifts
     useEffect ( () => {
                 
      Axios.get('http://localhost:8800/api/shifts')
             .then ((res) =>  {
                  setLstShifts(res.data)
                   // console.log (`res.data:  ${res.data}`) 
               })
             .catch( (err) => {
                  setLstShifts([])  
                   console.log (err);
                   alert(err)
               });
         
    }, []) 


    



    const inputOpenFileRef = React.createRef()

    const showOpenFileDlg = () => {
          
          inputOpenFileRef.current.click()
      }

      

    return (
    
     <Form noValidate autoComplete='off' >
    
       <Grid container direction="row" item xs={12} alignItems="flex-start" justify="space-between"  padding-top padding-bottom padding={1.5}>
    
         <Grid container direction="column" item xs={8} alignItems="flex-start" justify="space-between"  padding-top padding-bottom >

                  <Grid container direction="row" alignItems="flex-start" item xs={6}>
                        <Grid container direction="column" alignItems="flex-start" item xs={6}>
                            <H1>Data Entry</H1> 
                        </Grid>
                        <Grid container direction="column" alignItems="flex-end" item xs={6}>
                            <CheckBox1 name='check' checked label='Active' onChange={ onInputChange } />    
                        </Grid>
                  </Grid>
                   
                  
                  <Grid container direction="row" justifyContent ='left' item xs={12}  justify="space-between"  >
                       <Grid container direction="column" alignItems="flex-start" item xs={6}  sx={{ padding: .3 }} >
                           <TextField name='EmployeeID' label='Employee ID' inputProps={{ style: { fontSize: "1rem", height: '31px', padding: '0 80px',}, }}  size="medium"  variant="filled" color='secondary'  required  />  
                       </Grid>
                       <Grid container direction="column" alignItems="flex-start" item xs={6}  sx={{ padding: .3 }} >
                           <TextField name='EnrollNo' label='EnrollNo' inputProps={{ style: { fontSize: "1rem", height: '31px', padding: '0 80px',}, }}  size="medium"  variant="filled" color='secondary'  />  
                       </Grid>
                  </Grid>


                  <Grid container direction="row" alignItems="flex-start" justifyContent ='left' item xs={12}  justify="space-between"  >
                      <Grid container direction="row" alignItems="flex-start" sx={{ padding: .3}} >
                        <TextField name='Lastname'   label='Lastname'    inputProps={{ style: { fontSize: "1rem", height: '31px', padding: '0 80px',}, }}   size="medium" variant="filled" fullWidth required/>
                      </Grid> 
                      <Grid container direction="row" alignItems="flex-start" sx={{ padding: .3 }} >
                        <TextField name='Firstname'  label='Firstname'   inputProps={{ style: { fontSize: "1rem", height: '31px', padding: '0 80px',}, }}  size="medium" variant="filled" fullWidth required/>
                      </Grid> 
                      <Grid container direction="row" alignItems="flex-start" sx={{ padding: .3 }} >
                        <TextField name='Middlename' label='Middlename'  inputProps={{ style: { fontSize: "1rem", height: '31px', padding: '0 80px',}, }} size="medium" variant="filled" fullWidth required/>
                      </Grid>
                      <Grid container direction="row" alignItems="flex-start" sx={{ padding: .3}} >
                        <TextField name='Address'      label='Address'   inputProps={{ style: { fontSize: "1rem", height: '35px', padding: '0 70px',}, }}  size="medium"  multiline rows={2} autoComplete="off" variant="filled" value={values.Address} onChange={onInputChange} fullWidth/>
                      </Grid>
                      <Grid container direction="row" alignItems="flex-start" sx={{ padding: .3 }} >
                        <TextField name='City' label='City'  inputProps={{ style: { fontSize: "1rem", height: '31px', padding: '0 80px',}, }} size="medium" variant="filled" fullWidth required value={values.City} onChange={onInputChange}  />
                      </Grid>
                      <Grid container direction="row" alignItems="flex-start" sx={{ padding: .3 }} >
                         <RadioGroup name=     'Gender'   label=    'Gender' value=    {values.Gender}
                                     items=    { [ {'id': 'Male', label: 'Male'}, {'id': 'Female', label: 'Female'}] }
                                     onChange= {onInputChange}
                        
                         />
                      </Grid>

       

                      <Grid container direction="row" alignItems="flex-start" sx={{ padding: .3 }} >
                            <Select
                                    name='PosID'
                                    label='Position'
                                    value={values.PosID} // {values.PosID === ''? 'Select Position': values.PosID  }
                                    onChange={onInputChange}
                                    options={ 
                                                getPositions().map( (item, idx) => {
                                                      return {id: item.PosID, name: item.Position}
                                                } ) 
                                            }
                                    variant="filled"            
                                               
                            />
                      </Grid>
                      

                     


                      <Grid container direction="row" alignItems="flex-start" sx={{ padding: .3 }} >
                      <Select
                                    name='StatusID'
                                    label='Working Status'
                                    value={values.StatusID}
                                    onChange={onInputChange}
                                    options={ 
                                          getWorkingStatus().map( (item, idx) => {
                                              return {id: item.StatusID, name: item.Status}
                                         }) 
                                      }
                                   variant="filled"
                             />
                       </Grid>      
                       
                       {/* <hr width="100%" color='gray'/> */}
                       <br /> <br /><br />
                       <Grid container direction="row" alignItems="flex-start" sx={{ padding: .3 }} >
                            <Select
                                    name='DeptID'
                                    label='Department'
                                    value = {values.DeptID}  // value={values.DeptID === ''? 'Select Department': values.DeptID  }
                                    onChange={onInputChange}
                                    options={ 
                                                getDepartments().map( (item, idx) => {
                                                      return {id: item.DeptID, name: item.Department}
                                                } ) 
                                            }
                                    variant="filled"            
                                               
 
                            />
                      </Grid>

                      <br /> <br /><br />
                      

                      {/* <hr width="100%" color='gray' style={{ height: '2px', 'background-color': '#ff0000', 'border-bottom': '3px dotted grey', display: 'block' }} /> */}
                      {/* <hr width="100%" color='gray' style={{ height: '2px', 'background-color': '#ff0000', 'border-bottom': '3px dotted grey', display: 'block', border: '1px dashed #ccc', 'border-top-style': 'dotted'  }} /> */}

                      <Grid container direction="row" alignItems="flex-start" sx={{ padding: .3}} >
                        <TextField name='Notes'      label='Notes'   inputProps={{ style: { fontSize: "1rem", height: '165px', padding: '0 70px',}, }}  size="medium"  multiline rows={5} autoComplete="off" variant="filled" value={values.Notes} onChange={onInputChange} fullWidth/>
                      </Grid> 

                   </Grid>
                  
            </Grid>

            
            { /*  
                  ====================================================================================================================================================================================================================================================================================================================================================================================================================================================================================================================
                  BEGIN: PHOTO & SETTINGS 
                  ====================================================================================================================================================================================================================================================================================================================================================================================================================================================================================================================
                   padding-top  padding-bottom 
            */}
            
            <Grid container direction="column" item xs={4} alignItems="flex-start" justify="space-between"     >
                
                 <Grid container direction="column" alignItems="center" justifyContent="center" item xs={12} Spacing={2} sx={{marginLeft: '4px'}} >
                      
                      <div width='100%'>
                            <img src={PhotoX} alt="Employee Photos" title='Employee photo.' align='center' width={260} height={260} style={{ cursor: 'pointer', borderRadius: '3%', }} />
                            <input ref={inputOpenFileRef} type="file" style={{ display: "none" }}/>
                            <Button sx={{width: '100%', padding: '8px', marginTop: '2px'}} variant='contained' onClick={showOpenFileDlg} > Change Photo </Button >      
                      </div> 
                </Grid>
                
                <br />
                
                <Grid container direction="column" alignItems="center" justifyContent="center" item xs={12} Spacing={2}  sx={{marginLeft: '4px'}}  >
                      
                      <div>
                      <Select
                                    name='ShiftID'
                                    label='Shift'
                                    value={values.ShiftID}
                                    onChange={onInputChange}
                                    options={ 
                                          getShifts().map( (item, idx) => {
                                              return {id: item.ShiftID, name: item.Shiftname}
                                         }) 
                                      }
                                    variant='outlined'  
                        />
                     

                            
                      </div>      
                    
                </Grid>
                
                <br />

                {/* Rest Day */}
                <Grid container direction="row" alignItems="center" justifyContent="left" item xs={12} Spacing={1} border={.5} borderColor='gray' borderBottom={2} sx={{borderTopLeftRadius: 5, borderTopRightRadius: 5 , borderBottomLeftRadius: 5, borderBottomRightRadius: 5, marginLeft: '4px', marginBottom: '4px', }} >
                        <Grid container direction="row" alignItems="center" justifyContent="center" item xs={12} border={.5} borderColor='gray' bgcolor='gray' sx={{bgcolor: 'gray', borderTopLeftRadius: 3, borderTopRightRadius: 3 }}  >
                              <span><font color='white'>Rest Day</font></span>
                        </Grid>
                        <Grid container direction="row" alignItems="center" justifyContent="center" item xs={12} Spacing={1.5} sx={{margin: '2.5px', }} >
                              
                              <Grid container direction="column" alignItems="flex-start" item xs={2} >
                                    <CheckBox1 name='check' size="small" checked label='Mon' onChange={ onInputChange } />    
                              </Grid>
                              <Grid container direction="column" alignItems="flex-start" item xs={2}>
                              <CheckBox1 name='check' checked label='Tue' onChange={ onInputChange } />    
                              </Grid>
                              <Grid container direction="column" alignItems="flex-start" item xs={2}>
                              <CheckBox1 name='check' checked label='Wed' onChange={ onInputChange } />    
                              </Grid>
                              <Grid container direction="column" alignItems="flex-start" item xs={2}>
                              <CheckBox1 name='check' checked label='Thu' onChange={ onInputChange } />    
                              </Grid>
                              <Grid container direction="column" alignItems="flex-start" item xs={2}>
                              <CheckBox1 name='check' checked label='Fri' onChange={ onInputChange } />  
                              </Grid>    
                        </Grid>
                        <Grid container direction="row" alignItems="center" justifyContent="center" item xs={12} Spacing={1.5} sx={{margin: '2.5px', }} >
                              
                                    <Grid container direction="column" alignItems="flex-start" item xs={2}>
                                    <CheckBox1 name='check' checked label='Sat' onChange={ onInputChange } />    
                                    </Grid>
                                    <Grid container direction="column" alignItems="flex-start" item xs={2}>
                                    <CheckBox1 name='check' checked label='Sun' onChange={ onInputChange } />    
                                    </Grid>
                                    
                        </Grid>
                </Grid>

                
                {/* Automatic O.T Settings */}
                <Grid container direction="row" alignItems="center" justifyContent="left" item xs={12} Spacing={0} border={.5} borderColor='gray' borderBottom={2} sx={{borderTopLeftRadius: 5, borderTopRightRadius: 5 , borderBottomLeftRadius: 5, borderBottomRightRadius: 5, marginLeft: '4px', marginBottom: '4px',  }} >
                        <Grid container direction="row" alignItems="center" justifyContent="center" item xs={12} border={.5} borderColor='gray' bgcolor='gray' sx={{bgcolor: 'gray', borderTopLeftRadius: 3, borderTopRightRadius: 3 }}  >
                              <span><font color='white'>Advance Setting</font></span>
                        </Grid>
                        <Grid container direction="row" alignItems="center" justifyContent="center" item xs={12} Spacing={0}>
                              
                              <Grid container direction="row" alignItems="center" item xs={10} borderBottom={1}>
                                    <CheckBox1 name='check' size="small" checked label='OT-E Computation (Auto)' onChange={ onInputChange } />    
                              </Grid>
                              <Grid container direction="row" alignItems="center" item xs={10} borderBottom={1}>
                                   <CheckBox1 name='check' size="small" checked label='OT-S Computation (Auto)' onChange={ onInputChange } />    
                              </Grid>
                              <Grid container direction="row" alignItems="center" item xs={10} borderBottom={1}> 
                                   <CheckBox1 name='check' size="small" checked label='DTR (Auto)' onChange={ onInputChange } />    
                              </Grid>
                              <Grid container direction="row" alignItems="center" item xs={10} >
                                   <CheckBox1 name='check' size="small" checked label='Allow Leave' onChange={ onInputChange } />    
                              </Grid>
                              
                        </Grid>
                         
                </Grid>
 
               
                {/* Exemptions */}
                 
                <Grid container direction="row" alignItems="center" justifyContent="left" item xs={12} Spacing={0} border={1} borderColor='gray' borderBottom={2} sx={{borderTopLeftRadius: 5, borderTopRightRadius: 5 , borderBottomLeftRadius: 5, borderBottomRightRadius: 5, marginLeft: '4px', }} >
                        <Grid container direction="row" alignItems="center" justifyContent="center" item xs={12} border={1} borderColor='gray' borderBottom={2} sx={{bgcolor: 'gray', borderTopLeftRadius: 3, borderTopRightRadius: 3 }}  >  
                        {/* border={.5} borderColor='gray' bgcolor='gray' borderTopLeftRadius={10} */}
                              <span><font color='white'>Exemptions</font></span>
                        </Grid>
                        <Grid container direction="row" alignItems="center" justifyContent="center" item xs={12} Spacing={0} >
                        
                              <Grid container direction="column" alignItems="center" justifyContent="center" item xs={6} Spacing={0} >
                                    
                                    <Grid container direction="row" alignItems="center" justifyContent="center" item xs={6}  >
                                          <CheckBox1 name='check' size="small" checked label='Lates' onChange={ onInputChange } />    
                                    </Grid>
                                    <Grid container direction="row" alignItems="center" justifyContent="center" item xs={6} >
                                    <CheckBox1 name='check' size="small" checked label='Undertime' onChange={ onInputChange } />    
                                    </Grid>
                                    <Grid container direction="row" alignItems="center" justifyContent="center" item xs={6}  > 
                                    <CheckBox1 name='check' size="small" checked label='Overtime' onChange={ onInputChange } />    
                                    </Grid>
                                    <Grid container direction="row" alignItems="center" justifyContent="center" item xs={6} >
                                    <CheckBox1 name='check' size="small" checked label='Absences' onChange={ onInputChange } />    
                                    </Grid>
                                    
                              </Grid>

                              <Grid container direction="column" alignItems="center" justifyContent="center" item xs={6} Spacing={0}>
                                    
                                    <Grid container direction="row" alignItems="center" item xs={10}  >
                                          <CheckBox1 name='check' size="small" checked label='Night Differential' onChange={ onInputChange } />    
                                    </Grid>
                                    <Grid container direction="row" alignItems="center" item xs={10} >
                                    <CheckBox1 name='check' size="small" checked label='Restday' onChange={ onInputChange } />    
                                    </Grid>
                                    <Grid container direction="row" alignItems="center" item xs={10}  > 
                                    <CheckBox1 name='check' size="small" checked label='Holiday' onChange={ onInputChange } />    
                                    </Grid>
                                    <Grid container direction="row" alignItems="center" item xs={10} >
                                    <CheckBox1 name='check' size="small" checked label='Holiday-Rest' onChange={ onInputChange } />    
                                    </Grid>
                                    
                              </Grid>
                        </Grid>      
                         
                </Grid>

            </Grid>
            { /*  
                  ====================================================================================================================================================================================================================================================================================================================================================================================================================================================================================================================
                  END: PHOTO & SETTINGS 
                  ====================================================================================================================================================================================================================================================================================================================================================================================================================================================================================================================
            */} 

                  
            
          </Grid>   
      </Form> 
   
    )

}



const EmployeeRates = () => {
    
      const [initialValue, setInitialValue] = useState({
                    EmployeeID:           '',
                    Payperiod:            'Semi-Monthly',
                    DeductionPeriod:      'Semi-Monthly',
                    EEMR_ID:              '',
      });
  

      const [LstEEMR, setLstEEMR] = useState([]);

      const {
               values, 
               setValues,
               onInputChange,
            } = useForm(initialValue);
  
  
      // EEMR
      useEffect ( () => {
                        
            Axios.get('http://localhost:8800/api/eemr')
                  .then ((res) =>  {
                        setLstEEMR(res.data)
                        // console.log (`res.data:  ${res.data}`) 
                  })
                  .catch( (err) => {
                        setLstEEMR([])  
                        console.log (err);
                        alert(err)
                  });
            
      }, [])  
      
  

      const getEEMR = () => {

            return LstEEMR;
      
      }
          

          

      // use this to apply your own customized theme.
      const theme = createTheme({
             palette:  {
                primary: {
                    main: "#D433FF",
                }
             },
             Typography: {
                  h1:  {
                     fontSize: "1000px",
                  }
             }
  
      })
  
     
      return (
      
       <Form>
        
        <Grid container direction="row" item xs={12} alignItems="flex-start" justify="space-between"   padding-top padding-bottom padding={1.5}>
             
              {/* COL1 */}
              <Grid container direction="column" item xs={4} alignItems="center" justify="space-between"  padding-top padding-bottom >
                  
                  <Grid container direction="column" alignItems="center" item xs={12}  marginLeft={6}>
                        <Grid container direction="column" alignItems="flex-start" item xs={6} marginBottom={.5}>
                            <Button sx={{width: '100%', padding: '8px', marginTop: '2px', }} variant='contained' > EEMR Settings </Button >      
                        </Grid>
                        <Grid container direction="column" alignItems="flex-start" item xs={12} border={1}>
                              <Grid container direction="column" alignItems="left" item xs={6} sx={{ marginBottom: '2px', marginLeft: '10px'}}>
                                 <CheckBox1 name='check' checked label='EEMR [Enabled]' onChange={ onInputChange } />    
                              </Grid>
                              <Grid container direction="column" alignItems="center" item xs={6} sx={{marginBottom: 1.5, marginTop: 1}}>
                                     <Select
                                          name='EEMR_ID'
                                          label='EEMR Setting'
                                          value={values.EEMR_ID } 
                                          onChange={onInputChange}
                                          options={ 
                                                      getEEMR().map((item, idx) => {
                                                              return {id: item.EEMR_ID, name: item.EEMR_ID}
                                                      }) 
                                                  }
                                          variant="filled"            
                                   />
                              </Grid>

                              <Grid item xs={12} border={0}  container direction="column" marginLeft={0} sx={{borderTopLeftRadius: 0, borderTopRightRadius: 0, marginBottom: 0 }}  >
                                    <Grid container direction="row" alignItems="center" justifyContent="center" item xs={12} border={0} borderColor='black' borderBottom={0} sx={{bgcolor: 'gray',  borderTopLeftRadius: 0, borderTopRightRadius: 0 }}  >  
                                          <span><font color='white'><b> Employee Rate </b></font></span>
                                    </Grid>
                                    <Grid container direction="row" alignItems="center" justifyContent="center" item xs={12} marginLeft={1}   >  
                                          <FormControl>
                                                <MUIRadioGroup row 
                                                            name='Payperiod'
                                                            value={values.Payperiod}
                                                            onChange={onInputChange}
                                                >
                                                      <FormControlLabel value='DailyRate'         control={<Radio />} label='Daily Rate' /> 
                                                      <FormControlLabel value='MontlyRate'        control={<Radio />} label='Monthly Rate' /> 
                                                      
                                                </MUIRadioGroup>
                                          </FormControl>
                                    </Grid>
                              </Grid>

                        </Grid>
                  </Grid>

              </Grid>

              {/* COL2 */}
             <Grid container direction="column" item xs={8} alignItems="center" justify="space-between" border='1px' borderColor='gray' padding-top padding-bottom >
                  
                  <Grid item xs={12} border={1} marginLeft={6} sx={{borderTopLeftRadius: 3, borderTopRightRadius: 3, marginBottom: 1 }}  >
                        <Grid container direction="row" alignItems="center" justifyContent="center" item xs={12} border={1} borderColor='gray' borderBottom={2} sx={{bgcolor: 'gray', borderTopLeftRadius: 3, borderTopRightRadius: 3 }}  >  
                              <span><font color='white'>Payperiod</font></span>
                        </Grid>
                        <Grid container direction="row" alignItems="center" justifyContent="center" item xs={12} marginLeft={1}   >  
                              <FormControl>
                                    <MUIRadioGroup row 
                                                name='Payperiod'
                                                value={values.Payperiod}
                                                onChange={onInputChange}
                                    >
                                          <FormControlLabel value='Daily'         control={<Radio />} label='Daily' /> 
                                          <FormControlLabel value='Weekly'        control={<Radio />} label='Weekly' /> 
                                          <FormControlLabel value='Semi-Monthly'  control={<Radio />} label='Semi-Monthly' /> 
                                          <FormControlLabel value='Monthly'       control={<Radio />} label='Monthly' /> 
                                    </MUIRadioGroup>
                              </FormControl>
                        </Grid>
                  </Grid>
                  
                  <Grid item xs={12} border={1} marginLeft={6} sx={{borderTopLeftRadius: 3, borderTopRightRadius: 3 }}  >
                        <Grid container direction="row" alignItems="center" justifyContent="center" item xs={12} border={1} borderColor='gray' borderBottom={2} sx={{bgcolor: 'gray', borderTopLeftRadius: 3, borderTopRightRadius: 3 }}  >  
                              <span><font color='white'>Deduction Period</font></span>
                        </Grid>
                        <Grid container direction="row" alignItems="center" justifyContent="center" item xs={12} marginLeft={1}   >  
                              <FormControl>
                                    {/* <FormLabel> Deduction Period </FormLabel> */}
                                    <MUIRadioGroup row 
                                                name='DeductionPeriod'
                                                value={values.Deductionperiod}
                                                onChange={onInputChange}
                                    >
                                          <FormControlLabel value='Daily'         control={<Radio />} label='Daily' /> 
                                          <FormControlLabel value='Weekly'        control={<Radio />} label='Weekly' /> 
                                          <FormControlLabel value='Semi-Monthly'  control={<Radio />} label='Semi-Monthly' /> 
                                          <FormControlLabel value='Monthly'       control={<Radio />} label='Monthly' /> 
                                    </MUIRadioGroup>

                              </FormControl>
                        </Grid>
                  </Grid>

             </Grid>

         </Grid>    
               
       
       {/* 
           ROW2 ================================================================================================================================================================================ 
       */}
      
         <Grid container direction="row" item xs={12} alignItems="flex-start" justify="space-between"   padding-top padding-bottom padding={1.5}>
             {/* COL1 */}
             <Grid container direction="column" item xs={6} alignItems="center" justify="space-between"  padding-top padding-bottom >
                       
                        <Grid container direction="row" alignItems="center" justifyContent="center" item xs={12} border={.5} borderColor='gray' borderBottom={2} sx={{bgcolor: 'gray', borderTopLeftRadius: 3, borderTopRightRadius: 0, marginBottom: 1 }}  >  
                              <span><font color='white'>EMPLOYEE RATES</font></span>
                        </Grid>
                        <Grid container direction="row" alignItems="center" justifyContent="center" item xs={12} marginLeft={1}   >  
                              <Grid container direction="row" alignItems="flex-start" justifyContent ='left' item xs={12}  justify="space-between"  >
                                    <Grid container direction="column" item xs={3}  alignItems="flex-start" sx={{ padding: 1, alignContent: 'left' }} >
                                          <span>Monthly Rate</span>
                                    </Grid> 
                                    <Grid container direction="column" item xs={6}  alignItems="flex-start" sx={{ padding: .3}} >
                                          <TextField name='BasicRPM'   inputProps={{ style: { fontSize: "1rem", height: '31px', padding: '0 8px',}, }}   size="medium" variant="outlined" fullWidth required/>
                                    </Grid> 
                              </Grid>
                              <Grid container direction="row" alignItems="flex-start" justifyContent ='left' item xs={12}  justify="space-between"  >
                                    <Grid container direction="column" item xs={3}  alignItems="flex-start" sx={{ padding: 1, alignContent: 'left' }} >
                                          <span>Daily Rate</span>
                                    </Grid> 
                                    <Grid container direction="column" item xs={6}  alignItems="flex-start" sx={{ padding: .3}} >
                                          <TextField name='BasicRPD'   inputProps={{ style: { fontSize: "1rem", height: '31px', padding: '0 8px',}, }}   size="medium" variant="outlined" fullWidth required/>
                                    </Grid> 
                              </Grid>
                              <Grid container direction="row" alignItems="flex-start" justifyContent ='left' item xs={12}  justify="space-between"  >
                                    <Grid container direction="column" item xs={3}  alignItems="flex-start" sx={{ padding: 1, alignContent: 'left' }} >
                                          <span>Working Days</span>
                                    </Grid> 
                                    <Grid container direction="column" item xs={6}  alignItems="flex-start" sx={{ padding: .3}} >
                                          <TextField name='BasicRPM'   inputProps={{ style: { fontSize: "1rem", height: '31px', padding: '0 8px',}, }}   size="medium" variant="outlined" fullWidth required/>
                                    </Grid> 
                              </Grid>
                              <Grid container direction="row" alignItems="flex-start" justifyContent ='left' item xs={12}  justify="space-between"  >
                                    <Grid container direction="column" item xs={3}  alignItems="flex-start" sx={{ padding: 1, alignContent: 'left' }} >
                                          <span>Hrs Work/Day </span>
                                    </Grid> 
                                    <Grid container direction="column" item xs={6}  alignItems="flex-start" sx={{ padding: .3}} >
                                          <TextField name='BasicRPM'   inputProps={{ style: { fontSize: "1rem", height: '31px', padding: '0 8px',}, }}   size="medium" variant="outlined" fullWidth required/>
                                    </Grid> 
                              </Grid>
                             
                        </Grid>
             </Grid>     
             {/* COL2 */}
             <Grid container direction="column" item xs={6} alignItems="center" justify="space-between"  padding-top padding-bottom >
                       <Grid container direction="row" alignItems="center" justifyContent="center" item xs={12} border={1} borderColor='gray' borderBottom={2} sx={{bgcolor: 'gray', borderTopLeftRadius: 0, borderTopRightRadius: 3 }}  >  
                              <span><font color='white'>PAYMENT OPTIONS</font></span>
                        </Grid>
                        <Grid container direction="row" alignItems="center" justifyContent="center" item xs={12} marginLeft={1}   >  
                              <FormControl>
                                    {/* <FormLabel> Deduction Period </FormLabel> */}
                                    <MUIRadioGroup row 
                                                name='DeductionPeriod'
                                                value={values.Deductionperiod}
                                                onChange={onInputChange}
                                    >
                                          <FormControlLabel value='ATM / PAYCARD'         control={<Radio />}     label='ATM / PAYCARD' /> 
                                          <FormControlLabel value='CASH'                  control={<Radio />}     label='CASH' /> 
                                          <FormControlLabel value='CHECK'                 control={<Radio />}     label='CHECK' /> 
                                          <FormControlLabel value='DIRECT DEPOSIT'        control={<Radio />}     label='DIRECT DEPOSIT' /> 
                                          <FormControlLabel value='MOBILE WALLET'         control={<Radio />}     label='MOBILE WALLET' /> 
                                    </MUIRadioGroup>

                              </FormControl>
                        </Grid>
             </Grid>     
         </Grid>             

       

     

        </Form> 
     
      )
  
  }
     
  const PersonalInformation = () => {
    
      const [initialValue, setInitialValue] = useState({
                    EmployeeID:   '',
                    Lastname:     '',
                    Firstname:    '',
                    Middlename:   '',
                    Gender:       'Male',
                    Address:      '',
                    City:         '',
                    Birthdate:    new Date(),
                    Hiredate:     new Date(),
                    Status:       'Probationary',
                    CivilStatus:  'Single',
                    Payperiod:    'Semi-Monthly',
                    Department:   '',
                    Active:       true,
                    DeptID:       '',
                    StatusID:     '',
      });
  
       
      const {
               values, 
               setValues,
               onInputChange,
            } = useForm(initialValue);
  
  
  
      
  
      // use this to apply your own customized theme.
      const theme = createTheme({
             palette:  {
                primary: {
                    main: "#D433FF",
                }
             },
             Typography: {
                  h1:  {
                     fontSize: "1000px",
                  }
             }
  
      })
  
     
      return (
      
       <Form>
        
            <Grid container direction="column" alignItems="center" justifyContent ='center' item xs={12}  justify="space-between" >
                
                  <Grid container direction="column" item xs={6} alignItems="center"  justify="space-between"  padding-top padding-bottom paddingRight={1} border={0} >
                        <Grid container direction="row" alignItems="center" justifyContent="center" item xs={12} border={1} borderColor='gray' borderBottom={2} sx={{bgcolor: 'gray', borderTopLeftRadius: 5, borderTopRightRadius: 5, borderBottomLeftRadius: 5, borderBottomRightRadius: 5 }}  >  
                              <span><font color='white'>GOV'T IDs</font></span>
                        </Grid>
                        <Grid container direction="row" alignItems="center" justifyContent="center" item xs={12} marginLeft={1}  marginBottom={1} marginTop={1} >  
                        
                              <Grid container direction="row" alignItems="flex-start" justifyContent ='left' item xs={12}  justify="space-between"  marginLeft={5}   >
                                    <Grid container direction="column" item xs={3}  alignItems="flex-start" sx={{ padding: 1, alignContent: 'left' }} >
                                          <span>TIN NUMBER</span>
                                    </Grid> 
                                    <Grid container direction="column" item xs={6}  alignItems="flex-start" sx={{ padding: .3}} >
                                          <TextField name='BasicRPM'   inputProps={{ style: { fontSize: "1rem", height: '31px', padding: '0 8px',}, }}   size="medium" variant="outlined" fullWidth required/>
                                    </Grid> 
                              </Grid>
                              <Grid container direction="row" alignItems="flex-start" justifyContent ='left' item xs={12}  justify="space-between"  marginLeft={5}>
                                    <Grid container direction="column" item xs={3}  alignItems="flex-start" sx={{ padding: 1, alignContent: 'left' }} >
                                          <span>SSS NUMBER</span>
                                    </Grid> 
                                    <Grid container direction="column" item xs={6}  alignItems="flex-start" sx={{ padding: .3}} >
                                          <TextField name='BasicRPD'   inputProps={{ style: { fontSize: "1rem", height: '31px', padding: '0 8px',}, }}   size="medium" variant="outlined" fullWidth required/>
                                    </Grid> 
                              </Grid>
                              <Grid container direction="row" alignItems="flex-start" justifyContent ='left' item xs={12}  justify="space-between"  marginLeft={5}>
                                    <Grid container direction="column" item xs={3}  alignItems="flex-start" sx={{ padding: 1, alignContent: 'left' }} >
                                          <span>PHILHEALTH NUMBER</span>
                                    </Grid> 
                                    <Grid container direction="column" item xs={6}  alignItems="flex-start" sx={{ padding: .3}} >
                                          <TextField name='BasicRPM'   inputProps={{ style: { fontSize: "1rem", height: '31px', padding: '0 8px',}, }}   size="medium" variant="outlined" fullWidth required/>
                                    </Grid> 
                              </Grid>
                              <Grid container direction="row" alignItems="flex-start" justifyContent ='left' item xs={12}  justify="space-between" marginLeft={5} >
                                    <Grid container direction="column" item xs={3}  alignItems="flex-start" sx={{ padding: 1, alignContent: 'left' }} >
                                          <span>PAG-IBIG NUMBER</span>
                                    </Grid> 
                                    <Grid container direction="column" item xs={6}  alignItems="flex-start" sx={{ padding: .3}} >
                                          <TextField name='BasicRPM'   inputProps={{ style: { fontSize: "1rem", height: '31px', padding: '0 8px',}, }}   size="medium" variant="outlined" fullWidth required/>
                                    </Grid> 
                              </Grid>
                        </Grid>
                  </Grid>
                  
                  <Grid container direction="column" item xs={6} alignItems="center" justify="space-between"  padding-top padding-bottom  border={0} >
                      
                        <Grid container direction="row" alignItems="center" justifyContent="center" item xs={12} border={1} borderColor='gray' borderBottom={2} sx={{bgcolor: 'gray', borderTopLeftRadius: 5, borderTopRightRadius: 5, borderBottomLeftRadius: 5, borderBottomRightRadius: 5 }}  >  
                              <span><font color='white'>BANKS</font></span>
                        </Grid>
                        <Grid container direction="row" alignItems="center" justifyContent="center" item xs={12} marginLeft={1}   marginBottom={1} marginTop={1} >  
                              <Grid container direction="row" alignItems="flex-start" justifyContent ='left' item xs={12}  justify="space-between"  marginLeft={5}   >
                                    <Grid container direction="column" item xs={3}  alignItems="flex-start" sx={{ padding: 1, alignContent: 'left' }} >
                                          <span>BANK NAME 1</span>
                                    </Grid> 
                                    <Grid container direction="column" item xs={6}  alignItems="flex-start" sx={{ padding: .3}} >
                                          <TextField name='BasicRPM'   inputProps={{ style: { fontSize: "1rem", height: '31px', padding: '0 8px',}, }}   size="medium" variant="outlined" fullWidth required/>
                                    </Grid> 
                              </Grid>
                              <Grid container direction="row" alignItems="flex-start" justifyContent ='left' item xs={12}  justify="space-between"  marginLeft={5}>
                                    <Grid container direction="column" item xs={3}  alignItems="flex-start" sx={{ padding: 1, alignContent: 'left' }} >
                                          <span>BANK ACCOUNT NUMBER</span>
                                    </Grid> 
                                    <Grid container direction="column" item xs={6}  alignItems="flex-start" sx={{ padding: .3}} >
                                          <TextField name='BasicRPD'   inputProps={{ style: { fontSize: "1rem", height: '31px', padding: '0 8px',}, }}   size="medium" variant="outlined" fullWidth required/>
                                    </Grid> 
                              </Grid>
                              <Grid container direction="row" alignItems="flex-start" justifyContent ='left' item xs={12}  justify="space-between"  marginLeft={5}>
                                    <Grid container direction="column" item xs={3}  alignItems="flex-start" sx={{ padding: 1, alignContent: 'left' }} >
                                          <span>BANK NAME 2</span>
                                    </Grid> 
                                    <Grid container direction="column" item xs={6}  alignItems="flex-start" sx={{ padding: .3}} >
                                          <TextField name='BasicRPM'   inputProps={{ style: { fontSize: "1rem", height: '31px', padding: '0 8px',}, }}   size="medium" variant="outlined" fullWidth required/>
                                    </Grid> 
                              </Grid>
                              <Grid container direction="row" alignItems="flex-start" justifyContent ='left' item xs={12}  justify="space-between" marginLeft={5} >
                                    <Grid container direction="column" item xs={3}  alignItems="flex-start" sx={{ padding: 1, alignContent: 'left' }} >
                                          <span>BANK ACCOUNT NUMBER</span>
                                    </Grid> 
                                    <Grid container direction="column" item xs={6}  alignItems="flex-start" sx={{ padding: .3}} >
                                          <TextField name='BasicRPM'   inputProps={{ style: { fontSize: "1rem", height: '31px', padding: '0 8px',}, }}   size="medium" variant="outlined" fullWidth required/>
                                    </Grid> 
                              </Grid>
                        </Grid>
                  </Grid>     
               
            </Grid>   
            
            
            <Grid container direction="row" alignItems="flex-start" justifyContent ='left' item xs={12}  justify="space-between"  >
                  <Grid container direction="row" alignItems="center" justifyContent="center" item xs={12} border={1} borderColor='gray' borderBottom={2} sx={{bgcolor: 'gray', borderTopLeftRadius: 5, borderTopRightRadius: 5, borderBottomLeftRadius: 5, borderBottomRightRadius: 5 }}  >  
                              <span><font color='white'>OTHER PERSONAL INFORMATION</font></span>
                  </Grid>

                  <Grid container direction="column" alignItems="flex-start" justifyContent ='left' item xs={6}  justify="space-between"  >

                        <Grid container direction="row" alignItems="flex-start" sx={{ padding: .3}} >
                              <TextField name='ProvincialAddress'   label='Provincial Add.'    inputProps={{ style: { fontSize: "1rem", height: '31px', padding: '0 80px',}, }}   size="medium" variant="filled" fullWidth />
                        </Grid> 
                        <Grid container direction="row" alignItems="flex-start" sx={{ padding: .3}} >
                              <TextField name='City'   label='Zip Code'    inputProps={{ style: { fontSize: "1rem", height: '31px', padding: '0 80px',}, }}   size="medium" variant="filled" fullWidth />
                        </Grid> 
                        <Grid container direction="row" alignItems="flex-start" sx={{ padding: .3}} >
                              <TextField name='ZipCode'   label='Zip Code'    inputProps={{ style: { fontSize: "1rem", height: '31px', padding: '0 80px',}, }}   size="medium" variant="filled" fullWidth />
                        </Grid> 
                        <Grid container direction="row" alignItems="flex-start" sx={{ padding: .3}} >
                              <TextField name='Landline #'   label='Land line'    inputProps={{ style: { fontSize: "1rem", height: '31px', padding: '0 80px',}, }}   size="medium" variant="filled" fullWidth />
                        </Grid> 
                        <Grid container direction="row" alignItems="flex-start" sx={{ padding: .3}} >
                              <TextField name='Mobile'   label='Mobile'    inputProps={{ style: { fontSize: "1rem", height: '31px', padding: '0 80px',}, }}   size="medium" variant="filled" fullWidth />
                        </Grid> 
                         
                        <Grid container direction="row" alignItems="flex-start" sx={{ padding: .3}} >
                              <TextField name='EmailAddress'   label='Email Add.'    inputProps={{ style: { fontSize: "1rem", height: '31px', padding: '0 80px',}, }}   size="medium" variant="filled" fullWidth />
                        </Grid> 
                        
                        <Grid container direction="row" alignItems="flex-start" sx={{ padding: .3}} >
                              <DatePicker 
                                          name='Birthdate'
                                          label='Date of Birth'
                                          value={values.Birthdate} 
                                          onChange={onInputChange}
                              /> 
                        </Grid> 
                        <Grid container direction="row" alignItems="flex-start" sx={{ padding: .3 }} >
                              <RadioGroup name=     'CivilStatus'   label=    'Civil Status' value=    {values.CivilStatus}
                                     items=    { [ {'id': 'Single', label: 'Single'}, {'id': 'Married', label: 'Married'}] }
                                     onChange= {onInputChange}
                        
                             /> 
                       </Grid>
                  </Grid> 

                  <Grid container direction="column" alignItems="flex-start" justifyContent ='left' item xs={6}  justify="space-between"  >
      
                        <Grid container direction="row" alignItems="flex-start" sx={{ padding: .3}} >
                              <TextField name='EmergencyContactNum'   label='Emergency Contact#'    inputProps={{ style: { fontSize: "1rem", height: '31px', padding: '0 80px',}, }}   size="medium" variant="filled" fullWidth />
                        </Grid> 
                        <Grid container direction="row" alignItems="flex-start" sx={{ padding: .3}} >
                              <TextField name='EmergencyContactPerson'   label='Contact Person'    inputProps={{ style: { fontSize: "1rem", height: '31px', padding: '0 80px',}, }}   size="medium" variant="filled" fullWidth />
                        </Grid> 
                        <Grid container direction="row" alignItems="center"  sx={{ padding: .3, marginTop: 1.5, border:0 }}  >
                            <TextField name='Memo' label='Memo' inputProps={{ style: { fontSize: "1rem", height: '220px', padding: '0 15px',}, }}  size="medium"  multiline rows={5} autoComplete="off" variant="filled" fullWidth />
                        </Grid>
                        
                  
                  </Grid> 
                 
                
                  <Grid item xs={6} md={12}  >

                      {/* <DatePicker 
                             name='Birthdate'
                             label='Date of Birth'
                             value={values.Birthdate} 
                             onChange={onInputChange}
                       /> */}
                       
                      { 
                        // values.Birthdate
                       // alert('jeff nice!')
                      
                     

                     //  <DatePicker 
                         //     name='Birthdate'
                      //        label='Date of Birth'
                              // value={values.Birthdate}
                              // onChange={onInputChange}
                     //  />  
                  }  

                  </Grid>

                  {/* <Grid item xs={6} md={12}  >
                      
                     <DateTimePicker label='Select Data & Time' /> 
                  
                  </Grid>

                  <Grid item xs={6} md={12}  >
                       <DateTimePickerStaticLandscape  /> 
                  </Grid> */}
                  
              
            </Grid>


        </Form> 
     
      )
  
  }


  const Miscellaneous = () => {
    
      const [initialValue, setInitialValue] = useState({
                    EmployeeID:   '',
                    Lastname:     '',
                    Firstname:    '',
                    Middlename:   '',
                    Gender:       'Male',
                    Address:      '',
                    City:         '',
                    Birthdate:    new Date(),
                    Hiredate:     new Date(),
                    Status:       'Probationary',
                    CivilStatus:  'Single',
                    Payperiod:    'Semi-Monthly',
                    Department:   '',
                    Active:       true,
                    DeptID:       '',
                    StatusID:     '',
      });
  
       
      const {
               values, 
               setValues,
               onInputChange,
            } = useForm(initialValue);
  
  
  
      
  
      // use this to apply your own customized theme.
      const theme = createTheme({
             palette:  {
                primary: {
                    main: "#D433FF",
                }
             },
             Typography: {
                  h1:  {
                     fontSize: "1000px",
                  }
             }
  
      })
  
     
      return (
      
       <Form>
        
            <Grid container direction="row" alignItems="flex-start" justifyContent ='left' item xs={12}  justify="space-between"  >
                  <Grid container direction="row" alignItems="center" justifyContent="center" item xs={12} border={1} borderColor='gray' borderBottom={2} sx={{bgcolor: 'gray', borderTopLeftRadius: 5, borderTopRightRadius: 5, borderBottomLeftRadius: 5, borderBottomRightRadius: 5, marginBottom: 1 }}  >  
                              <span><font color='white'>GOV'T CONTRIBUTION AND TAXES</font></span>
                  </Grid>

                  <Grid container direction="column" alignItems="flex-start" justifyContent ='left' item xs={3}  justify="space-between"   >

                        <Grid container direction="row" alignItems="center" sx={{ padding: 0, bgcolor: 'cyan', }}  marginBottom={1}  >
                             <Grid container direction="row" xs={10} alignItems="flex-start" justifyContent ='center'  border={0}>
                                 <CheckBox1 name='check' checked label='SSS Contribution' onChange={ onInputChange }  />    
                              </Grid>
                        </Grid> 
                        <Grid container direction="row" xs={10} alignItems="center" marginLeft={2.5} sx={{ padding: .3, bgcolor: 'divider', borderTopLeftRadius: 5, borderTopRightRadius: 5, borderBottomLeftRadius: 5, borderBottomRightRadius: 5, marginBottom: 1 }}  >  
                              <Grid container direction="column" xs={6} alignItems="center" sx={{ padding: .3}} >
                                  <CheckBox1 name='check' checked label='Table' onChange={ onInputChange } />    
                              </Grid>
                              <Grid container direction="column" xs={6} alignItems="flex-start" sx={{ padding: .3}} >
                                  <CheckBox1 name='check' checked label='Manual' onChange={ onInputChange } />    
                              </Grid>
                        </Grid> 
                        
                        <Grid container direction="row" alignItems="flex-start" sx={{ padding: .3}} >
                              <TextField name='ZipCode'   label='SSS ER'    inputProps={{ style: { fontSize: "1rem", height: '31px', padding: '0 80px',}, }}   size="medium" variant="filled" fullWidth />
                        </Grid> 
                        <Grid container direction="row" alignItems="flex-start" sx={{ padding: .3}} >
                              <TextField name='Landline #'   label='EC ER'    inputProps={{ style: { fontSize: "1rem", height: '31px', padding: '0 80px',}, }}   size="medium" variant="filled" fullWidth />
                        </Grid> 
                        <Grid container direction="row" alignItems="flex-start" sx={{ padding: .3}} >
                              <TextField name='Mobile'   label='SSS EE'    inputProps={{ style: { fontSize: "1rem", height: '31px', padding: '0 80px',}, }}   size="medium" variant="filled" fullWidth />
                        </Grid> 
                         
                         
                  </Grid> 

                  <Grid container direction="column" alignItems="flex-start" justifyContent ='left' item xs={3}  justify="space-between"  >

                        <Grid container direction="row" alignItems="center" sx={{ padding: 0, bgcolor: 'cyan'}}  marginBottom={1}>
                              <CheckBox1 name='check' checked label='PhilHealth Contribution' onChange={ onInputChange } />    
                        </Grid> 
                        <Grid container direction="row" xs={10} alignItems="center" marginLeft={2.5} sx={{ padding: .3, bgcolor: 'divider', borderTopLeftRadius: 5, borderTopRightRadius: 5, borderBottomLeftRadius: 5, borderBottomRightRadius: 5, marginBottom: 1 }}  >  
                              <Grid container direction="column" xs={6} alignItems="center" sx={{ padding: .3}} >
                                  <CheckBox1 name='check' checked label='Table' onChange={ onInputChange } />    
                              </Grid>
                              <Grid container direction="column" xs={6} alignItems="flex-start" sx={{ padding: .3}} >
                                  <CheckBox1 name='check' checked label='Manual' onChange={ onInputChange } />    
                              </Grid>
                        </Grid> 
                        
                        <Grid container direction="row" alignItems="flex-start" sx={{ padding: .3}} >
                              <TextField name='ZipCode'   label='MED ER'    inputProps={{ style: { fontSize: "1rem", height: '31px', padding: '0 80px',}, }}   size="medium" variant="filled" fullWidth />
                        </Grid> 
                        <Grid container direction="row" alignItems="flex-start" sx={{ padding: .3}} >
                              <TextField name='Landline #'   label='MED EE'    inputProps={{ style: { fontSize: "1rem", height: '31px', padding: '0 80px',}, }}   size="medium" variant="filled" fullWidth />
                        </Grid> 
                        
                         
                         
                  </Grid> 

                  <Grid container direction="column" alignItems="flex-start" justifyContent ='left' item xs={3}  justify="space-between"  >

                        <Grid container direction="row" alignItems="center" sx={{ padding: 0, bgcolor: 'cyan'}}  marginBottom={1}>
                              <CheckBox1 name='check' checked label='Pag-IBIG Contribution' onChange={ onInputChange } />    
                        </Grid> 
                        <Grid container direction="row" xs={10} alignItems="center" marginLeft={2.5} sx={{ padding: .3, bgcolor: 'divider', borderTopLeftRadius: 5, borderTopRightRadius: 5, borderBottomLeftRadius: 5, borderBottomRightRadius: 5, marginBottom: 1 }}  >  
                              <Grid container direction="column" xs={6} alignItems="center" sx={{ padding: .3}} >
                                  <CheckBox1 name='check' checked label='Table' onChange={ onInputChange } />    
                              </Grid>
                              <Grid container direction="column" xs={6} alignItems="flex-start" sx={{ padding: .3}} >
                                  <CheckBox1 name='check' checked label='Manual' onChange={ onInputChange } />    
                              </Grid>
                        </Grid> 
                        
                        <Grid container direction="row" alignItems="flex-start" sx={{ padding: .3}} >
                              <TextField name='ZipCode'   label='Employer'    inputProps={{ style: { fontSize: "1rem", height: '31px', padding: '0 80px',}, }}   size="medium" variant="filled" fullWidth />
                        </Grid> 
                        <Grid container direction="row" alignItems="flex-start" sx={{ padding: .3}} >
                              <TextField name='Mobile'   label='Employee'    inputProps={{ style: { fontSize: "1rem", height: '31px', padding: '0 80px',}, }}   size="medium" variant="filled" fullWidth />
                        </Grid> 
                         
                         
                  </Grid>  

                  <Grid container direction="column" alignItems="flex-start" justifyContent ='left' item xs={3}  justify="space-between"  >

                        <Grid container direction="row" alignItems="center" sx={{ padding: 0, bgcolor: 'cyan'}}  marginBottom={1}>
                              <CheckBox1 name='check' checked label='W/TAX' onChange={ onInputChange } />    
                        </Grid> 
                        <Grid container direction="row" xs={10} alignItems="center" marginLeft={2.5} sx={{ padding: .3, bgcolor: 'divider', borderTopLeftRadius: 5, borderTopRightRadius: 5, borderBottomLeftRadius: 5, borderBottomRightRadius: 5, marginBottom: 1 }}  >  
                              <Grid container direction="column" xs={6} alignItems="center" sx={{ padding: .3}} >
                                  <CheckBox1 name='check' checked label='Table' onChange={ onInputChange } />    
                              </Grid>
                              <Grid container direction="column" xs={6} alignItems="flex-start" sx={{ padding: .3}} >
                                  <CheckBox1 name='check' checked label='Manual' onChange={ onInputChange } />    
                              </Grid>
                        </Grid> 
                        
                        <Grid container direction="row" alignItems="flex-start" sx={{ padding: .3}} >
                              <TextField name='ZipCode'   label='Per Month'    inputProps={{ style: { fontSize: "1rem", height: '31px', padding: '0 80px',}, }}   size="medium" variant="filled" fullWidth />
                        </Grid> 
                         
                         
                         
                  </Grid> 
                 
                 
            </Grid>


        </Form> 
     
      )

}

export const Attachments = () => {


      const StyledTableCell = styledMui(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          backgroundColor: theme.palette.common.black,
          color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
          fontSize: 12,
        },
      }));
      
      const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
          backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
          border: 0,
        },
      }));
      
      function createData( name, calories, fat, carbs, protein) {
        return { name, calories, fat, carbs, protein };
      }
    
      
      const rows = [
        createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
        createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
        createData('Eclair', 262, 16.0, 24, 6.0),
        createData('Cupcake', 305, 3.7, 67, 4.3),
        createData('Gingerbread', 356, 16.0, 49, 3.9),
      ];
      
      
        return (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Dessert (100g serving)</StyledTableCell>
                  <StyledTableCell align="right">Calories</StyledTableCell>
                  <StyledTableCell align="right">Fat&nbsp;(g)</StyledTableCell>
                  <StyledTableCell align="right">Carbs&nbsp;(g)</StyledTableCell>
                  <StyledTableCell align="right">Protein&nbsp;(g)</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <StyledTableRow key={row.name}>
                    <StyledTableCell component="th" scope="row">
                      {row.name}
                    </StyledTableCell>
                    <StyledTableCell align="right">{row.calories}</StyledTableCell>
                    <StyledTableCell align="right">{row.fat}</StyledTableCell>
                    <StyledTableCell align="right">{row.carbs}</StyledTableCell>
                    <StyledTableCell align="right">{row.protein}</StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        );
      
    
    }


 export default Profile;

//  <TextField  InputLabelProps={{ shrink: true }} label="Lastname" placeholder='Enter Lastname' sx={{ width: '250px', height: '5px',}}/>
